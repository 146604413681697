<div class="alert-box">
    <div class="modal-header">
        <h4 class="modal-title">Confirm</h4>
        <button type="button" class="close" aria-label="Close" (click)="_bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Are you sure, you want to logout?
    </div>
    <div class="modal-footer signout-confirmation-btn-group">
        <button type="button" class="btn btn-primary signout-confirmation-btn" (click)="onConfirm()">Yes</button>
        <button type="button" class="btn btn-primary signout-confirmation-btn" (click)="onCancel()">No</button>        
    </div>
</div>