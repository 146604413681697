import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { AccountService } from "src/app/core/account.service";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/core/authentication.service";
import { TransactionStatus } from "../../../../core/model/transaction.status";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import * as moment from "moment";
import { TransactionsModalComponent } from "../transactions-modal/transactions-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PaymentsModalComponent } from "../payments-modal/payments-modal.component";

@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.scss"],
})
export class PaymentsComponent implements OnInit {
  currentTransactions: any[] = [];
  spendingAvailability: number;
  index: number;
  rowExpanded: boolean = false;
  indicator: boolean = true;

  @ViewChild("invalidDateTemplate", { static: true })
  invalidDateTemplate: TemplateRef<any>;
  constructor(


    public accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private authService: AuthenticationService,
    // public modalService: BsModalService,
    private modalService: NgbModal
    // public modalController:ModalController,
  ) { }
  //latest available transaction date to latest avaible
  modalRef: BsModalRef;
  isTransactionsLoaded: boolean = false;
  transactionHistory: any;
  transactionHistoryLength = 0;
  filteredTransactions: any;
  filteredTransactionsLength = 0;
  transactionStatus = TransactionStatus;
  myMath = Math;
  itemsPerPage = 20;
  popoverInfo: any;
  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();
  minDate = new Date("01-01-1990");
  // minDate: Date;
  currentPage: any;
  selectedDateRange: any;
  searchKey: string;
  startDate: Date;
  endDate: Date;
  pageSize = 10;
  pageIndex = 1;
  debounceTimer: any;
  totalRowCount = 0;
  sortBy = "-trxn.recDate";
  whereFilter = "";
  filterStatus = [
    { text: "CLEARED", value: "cleared" },
    { text: "FAILED", value: "failed" },
  ];

  dateLoader = true;

  tokenCall: any;
  transactionHistoryCall: any;
  gotoLink(item){

    // routerLink="/getTransactionHistory/{{oppId}}/{{appname}}/{{token}}/transactions/{{item['tid']}}" 
    try {
      this.handleCancel();
    } catch (error) {
      
    }
    this.router.navigate(['/getTransactionHistory/'+this.oppId+'/appname/'+this.token+'/transactions/'+item['tid']])
  }
  loadDataFromServer(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    filter: string
  ): void {
    // this.setAccountStatementState();

    if (!this.currentTransactions) {
      if (!this.currentTransactions.length) {
        this.isTransactionsLoaded = false;
      }
    }
    let begDate, endDate;
    // begDate = '01/01/2021';
    // endDate = '09/28/2021';
    begDate = moment().startOf("year").format("MM/DD/YYYY");
    endDate = moment().subtract(2, "day").format("MM/DD/YYYY");
    // this.randomUserService.getUsers(pageIndex, pageSize, sortField, sortOrder, filter).subscribe(data => {
    // this.accountService.revenuedTest().subscribe((data) => {});
    this.transactionHistoryCall = this.accountService
      .getPaymentCalendarHistoryIframe (begDate, endDate,this.oppId,this.token,this.appname)
      .subscribe(
        (data) => {
          data = JSON.parse(data);
          if (this.tabName === "payments") {
            if (this.activeId) {
              this.isTransactionsLoaded = false;
              setTimeout(() => {
                // $("#expand-" + this.activeId).trigger("click");
                this.showPaymentModal(this.activeId, null);
                // this.isTransactionsLoaded = true;
              }, 5000);
            }
          }

          this.isTransactionsLoaded = true;
          if (
            typeof data.array.Payment === "object" &&
            !Array.isArray(data.array.Payment) &&
            data.array.Payment !== null
          ) {
            const trxn = data.array.Payment;
            data.array.Payment = trxn;
          }
          // total pages -> Math.ceil(data.array.$attributes.rowCnt/10)
          this.totalRowCount = data.array.$attributes.rowCnt;

          this.transactionHistory = data.array.Payment || [];
          this.filteredTransactions = this.transactionHistory;
          this.currentTransactions = this.transactionHistory;
          this.filteredTransactionsLength = this.filteredTransactions.length;
          // if (this.filteredTransactionsLength > 0) {
          //   this.maxDate = new Date(this.filteredTransactions[0].trxn.recDate);
          //   this.minDate = new Date(
          //     this.maxDate.getTime() - 61 * 24 * 60 * 60 * 1000
          //   );

          //   this.bsRangeValue = [this.minDate, this.maxDate];
          //   this.selectedDateRange = this.bsRangeValue;
          //   this.onDateChange(this.selectedDateRange);
          // }

          this.dateLoader = false;
        },
        (error) => {
          this.isTransactionsLoaded = true;
          // this.createCase(
          //   "Error",
          //   "Website Support",
          //   typeof error === "object" ? JSON.stringify(error) : error,
          //   `Transaction History Failure`
          // );
        }
      );
  }

  createCase(
    status: string,
    reason: string,
    description: string,
    subject: string
  ) {
    this.accountService
      .createCase({
        accId: localStorage.getItem("acctId"),
        status,
        reason,
        subject,
        description,
        submitter: window.localStorage.getItem("email"),
      })
      .subscribe(
        (res) => {
          console.log(res);
        },
        (er) => {
          console.error(er);
          this.totalRowCount = 0;
          this.transactionHistory = [];
          this.filteredTransactions = this.transactionHistory;
          this.currentTransactions = this.transactionHistory;
        }
      );
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    console.log(params);
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort.filter((item) => item.value !== null);
    let sortField = "";
    currentSort.forEach((element) => {
      sortField = sortField + "trxn." + element.key;
      if (element && element.value === "descend") {
        sortField = "-" + sortField + ",";
      } else {
        sortField = sortField + ",";
      }
    });
    if (sortField.substr(-1) === ",") {
      sortField = sortField.substr(0, sortField.length - 1);
    }

      this.loadDataFromServer(pageIndex, pageSize, sortField, "");
  }
  getTitleForHistory(arg: any): string {
    if (arg && arg.pmtCode) {
      if (arg.pmtCode === "P") {
        return "Payment";
      } else if (arg.pmtCode === "B") {
        return "Bounced Payment";
      } else if (arg.pmtCode === "Y") {
        return "PayNow Payment";
      } else {
        return "NA";
      }
    } else {
      return "NA";
    }
  }
  token = "";
  oppId = "";
  appname = "";

  tabName = "transactions";
  activeId = null;
  refreshRequired=false;
  ngOnInit() {
    this.activatedRoute.params.subscribe((res) => {
      this.oppId = this.activatedRoute.snapshot.paramMap.get("oppId");
      this.token = this.activatedRoute.snapshot.paramMap.get("token");
      this.appname = this.activatedRoute.snapshot.paramMap.get("appname");

           if (this.oppId === 'null' || this.token === 'null' || this.appname === 'null') {
              this.refreshRequired = true;
              return;
          }

      this.currentTransactions = [];
      this.pymtDetails = {};
      this.isTransactionsLoaded = false;
      this.expandSet.clear();

      if (this.router.url.split("/")[5]) {
        this.tabName = this.router.url.split("/")[5];
      }

      if (this.router.url.split("/")[6]) {
        this.activeId = this.router.url.split("/")[6];

        if (this.tabName === "payments") {
          this.onExpandChange(this.activeId, null);
        }
      }

      this.loadDataFromServer(this.pageIndex, this.pageSize, this.sortBy, "");
    });



    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      // console.log("mobile device");
      // this.currentTransactions1.forEach(element => {
      //   if (parseInt(element.id) == parseInt(this.router.url.split('/')[3].trim())) {
      //     this.showPaymentModal(element);
      //   }
      // });
    } else {
      // false for not mobile device
      // console.log("not mobile device");
      // this.onExpandChange(parseInt(this.router.url.split('/')[3].trim()), true);
    }
  }
  onDateChange(dateRange?: any, dateType?) {
    if (dateType === "minDate" && this.selectedDateRange) {
      this.selectedDateRange[0] = dateRange;
    }
    if (dateType === "maxDate" && this.selectedDateRange) {
      this.selectedDateRange[1] = dateRange;
    }
    if (
      this.selectedDateRange &&
      this.selectedDateRange[0].getTime() > this.selectedDateRange[1].getTime()
    ) {
      // this.modalRef = this.modalService.show(this.invalidDateTemplate);
      // this.ngOnInit();
    } else {
      if (this.transactionHistory) {
        this.filteredTransactions = this.transactionHistory.filter((t, i) => {
          var tranTime = new Date(t.trxn.recDate).getTime();
          return (
            tranTime >= this.selectedDateRange[0].getTime() &&
            tranTime <= this.selectedDateRange[1].getTime()
          );
        });
        this.currentTransactions = this.filteredTransactions.sort(
          (a, b) =>
            Number(moment(b.trxn.recDate).format("YYYYMMDD")) -
            Number(moment(a.trxn.recDate).format("YYYYMMDD"))
        );
      }
    }




  }



  debounce(func, delay) {
    return () => {
      const context = this;
      const args = arguments;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }


  gotoaccount() {
    this.router.navigate(["/dashboard"]);
  }
  pageChanged(event: PageChangedEvent) {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.currentTransactions = this.transactionHistory.slice(
      startItem,
      endItem
    );
  }

  setAccountStatementState() {
    this.accountService.globalDataLoader = true;

    this.currentTransactions =
      this.accountService.accountStatementState.currentTransactions;
    if (this.currentTransactions) {
      if (this.currentTransactions.length) {
        this.isTransactionsLoaded = true;
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy();
  }
  onDestroy() {
    // within the ngOnDestroy of your component
    if (this.tokenCall) this.tokenCall.unsubscribe();
    if (this.transactionHistoryCall) this.transactionHistoryCall.unsubscribe();
    // within the ngOnDestroy of your component
  }

  expandSet = new Set<number>();
  onExpandChange(id: number, checked: boolean): void {
    let isChecked = this.expandSet.has(id);
    this.expandSet.clear();
    if (!isChecked) {
      this.expandSet.add(id);
      this.getPaymentDetails(id);
    } else {
      this.pymtDetails = {};
      this.expandSet.delete(id);
    }
  }

  pymtDetails: any = {};

  isTransactionDetailsLoaded: boolean = true;
  getPaymentDetails(id) {
    this.pymtDetails = {};
    this.isTransactionDetailsLoaded = false;
    this.isTransactionsLoaded = true;
    this.accountService.getPaymentDetails(id,this.oppId,this.token,this.appname).subscribe(
      (data) => {
        this.pymtDetails = JSON.parse(data);


        if (this.pymtDetails?.RevPayment?.pymt?.RevPymtDetail) {
          this.pymtDetails?.RevPayment?.pymt?.RevPymtDetail.sort(function (a: any, b: any) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.sysDate).getTime() - new Date(a.sysDate).getTime();
          });
        }

        this.isTransactionDetailsLoaded = true;
        this.isTransactionsLoaded = true;
      },
      (error) => {
        this.isTransactionDetailsLoaded = true;
        this.isTransactionsLoaded = true;
      }
    );
  }

  expandInnerTable(id, event) {
    console.log(event);
    this.index = id;
    this.rowExpanded = !this.rowExpanded;
  }

  isVisible = false;
  isOkLoading = false;
  modalData = null;

  showPaymentModal(id, transaction) {
    this.isVisible = true;
    // this.modalData = transaction;
    this.getPaymentDetails(id);
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  trxnDetails:any = {};
  getTransactionDetails(id) {
    this.trxnDetails = {};
    this.isTransactionDetailsLoaded = false;
    this.isTransactionsLoaded = true;
    this.accountService.getTransactionDetails(id,this.oppId,this.token,this.appname).subscribe(
      (data) => {
        this.trxnDetails = JSON.parse(data);

        if (this.trxnDetails?.RevTrxnDetail?.pymt?.RevPymtDetail) {
          this.trxnDetails.RevTrxnDetail.pymt.RevPymtDetail.sort(function (a: any, b: any) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.sysDate).getTime() - new Date(a.sysDate).getTime();
          });
        }


        this.isTransactionDetailsLoaded = true;
        this.isTransactionsLoaded = true;
      },
      (error) => {
        this.isTransactionDetailsLoaded = true;
        this.isTransactionsLoaded = true;
      }
    );
  }
  isTransactionVisible=false;
  showTransactionModal(id, transaction) {
    try {
      this.handleCancel();
      this.handleTransactionsCancel();
    } catch (error) {
      
    }
    this.isTransactionVisible = true;
    // this.modalData = transaction;
    this.getTransactionDetails(id);
  }

  handleTransactionsCancel(): void {
    this.isTransactionVisible = false;
  }




  openPaymentModal(pid,trxn) {
    const modalRef = this.modalService.open(PaymentsModalComponent,{ size: 'lg' });
    modalRef.componentInstance.oppId =this.oppId;
    modalRef.componentInstance.token =this.token;
    modalRef.componentInstance.appname =this.appname;
    modalRef.componentInstance.pid =pid;
  }


  openTransactionModal(tid,trxn) {
    const modalRef = this.modalService.open(TransactionsModalComponent,{ size: 'lg' });
    modalRef.componentInstance.oppId =this.oppId;
    modalRef.componentInstance.token =this.token;
    modalRef.componentInstance.appname =this.appname;
    modalRef.componentInstance.tid =tid;
  }
  searchItem=null;
}
