import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: "root"
})
@Component({
  selector: 'modal-content',
  template: `
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5>{{message}}</h5>
    </div>
  `
})

export class LoginFormErrorModalComponent implements OnInit {
  title: string;
  closeBtnName: string;
  message: string;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
}