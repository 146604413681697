import { Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { AccountService } from '../../core/account.service';
import { io } from "socket.io-client";
import { AuthenticationService } from 'src/app/core/authentication.service';
import * as moment from 'moment-timezone';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { interval } from 'rxjs'
import { Socket } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

let notificationsTimerRef;

@Component({
  selector: 'app-header-right',
  templateUrl: './header-right.component.html',
  styleUrls: ['./header-right.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderRightComponent implements OnInit, OnDestroy {

  @Input()
  legalName: any;
  canSwitchAccounts;
  notifications: any;
  newNotificationCount: number;
  fetchedIntialNotifications = false;
  clientEmails: any;
  isVisible = false;
  isOkLoading = false;
  selectedAccount = '';
  accountLast: any;
  showMoreNotificationInfo = null;
  showMoreNotificationInfoModal = false;
  notificationsPopOverVisible = false;
  profileDropDownToggle = false;


  @Output() callLogout = new EventEmitter();

  constructor(private socket: Socket, private nzNotificationService: NzNotificationService, public authService: AuthenticationService, public accountService: AccountService, public router: Router,
    private sanitizer: DomSanitizer) {

    try {
      this.socket.connect();
    } catch (err) {
      //do nothing
      console.log(err)
    }



  }

  ngOnInit(): void {
    this.canSwitchAccounts = window.localStorage.getItem('userMode') === 'operator';
    if (this.canSwitchAccounts) {
      this.authService.getClientEmails({}, { 'authorization': 'Bearer ' + JSON.parse(window.localStorage.getItem('token')).id_token, 'appName': environment.appName }).subscribe((details) => {
        this.clientEmails = details;
      })
    }
    this.accountService.getAccountSummary().subscribe((res) => {
      res = JSON.parse(res);
      this.legalName = res.RevAccountSummary.summary.legalName
      localStorage.setItem('legalName', res.RevAccountSummary.summary.legalName)
      this.fetchNotifications();
    }, error => {
    });

    if (notificationsTimerRef) {
      clearInterval(notificationsTimerRef);
    }
    notificationsTimerRef = setInterval(() => {
      if (window.localStorage.getItem("token")) {
        this.authService.isTokenIdValid().subscribe(
          (res) => {
            this.fetchNotifications();
          },
          () => {
            // this.toastr.warning("Session Expired, Login Again!");
            window.localStorage.removeItem("token");
            this.router.navigate(['/login'])
            window.location.reload();
          }
        );
      }
    }, 60000);

    this.socket.on('connect', () => {
      console.log("Init socket event ");
      this.socket.on('healthcheck', hc => {
        console.log("socket healthcheck pass ");
      });

      this.socket.on('resetNotifications', res => {
        this.fetchNotifications(true);
      });

      this.socket.on('newNotifications', res => {
        let newNotifications = res.data.notifications;
        newNotifications = newNotifications.map(i => ({ ...i, date: moment.tz(i.date, "America/New_York") }));
        newNotifications = newNotifications.filter(i => !this.notifications.map(i => i._id).includes(i._id));
        newNotifications.forEach(element => {
          this.notifications.unshift(element);
        });
        if (this.notifications.length > 5) {
          for (let i = 0; i < this.notifications.length - 5; i++) {
            this.notifications.pop();
          }
        }
        this.newNotificationCount = res.data.unreadNotificationCount;
        this.fetchedIntialNotifications = true;
        if (this.newNotificationCount)
          this.accountService.showNotifsCount = true;
        else
          this.accountService.showNotifsCount = false;
        console.log("Received socket event for newNotifications ", this.notifications);
      });

      this.socket.on('newNotificationsError', error => {
        console.log("newNotificationsError ", error)
      });


      if (localStorage.getItem('oppId') && localStorage.getItem('oppId') !== null) {
        this.socket.emit('subscribeToNotifications', { oppId: localStorage.getItem('oppId') });
      }


    });
  }

  ngOnDestroy(): void {
    if (notificationsTimerRef) {
      clearInterval(notificationsTimerRef);
    }
    this.socket.disconnect();
  }

  //switching user by operator - OK clicked!
  handleOk(): void {
    this.isOkLoading = true;
    this.authService.getUsersOfOperator({ clientEmails: [this.selectedAccount], email: localStorage.getItem('email') }, { authorization: 'Bearer ' + JSON.parse(window.localStorage.getItem('token')).id_token, appName: environment.appName }).subscribe((details) => {

      window.localStorage.setItem('oppId', details[0].app_metadata.opportunityId);
      /*unset cache for old user*/
      this.accountService.globalDataLoader = true;
      this.accountService.unsetAccountStatementState();
      /*unset cache for old user*/

      this.accountService.getAccountSummary().subscribe((res) => {
        res = JSON.parse(res);
        this.legalName = res.RevAccountSummary.summary.legalName;
        this.router.navigate(['/dashboard']);
        this.isVisible = false;
        this.isOkLoading = false;
        window.location.reload();
      }, error => {
      });
    });
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  goToNotifications(): void {
    this.router.navigate(['/dashboard/notifications']);
    this.notificationsPopOverVisible = false;
  }

  closePopOver() {
    this.notificationsPopOverVisible = false;
  }

  toggleDropdown() {
    console.log(this.profileDropDownToggle);
    // this.profileDropDownToggle = !this.profileDropDownToggle;
  }

  viewMoreHandler(notification: any): void {
    this.showMoreNotificationInfoModal = true;
    this.showMoreNotificationInfo = notification;
    this.notificationsPopOverVisible = false;
    if (!notification.read) {
      setTimeout(() => {
        this.markNotificationRead(notification._id)
      }, 250);
    }
  }
  sanitizeUrl(url: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(url);
  }
  handleMoreInfoClose(): void {
    this.showMoreNotificationInfoModal = false;
    setTimeout(() => {
      this.showMoreNotificationInfo = null;
    }, 250);
  }

  fetchNotifications(silentSync = false) {
    this.accountService.getQuickViewNotifications().subscribe((res) => {
      res = JSON.parse(res);
      this.notifications = res && res.notifications && res.notifications.length > 0 ? res.notifications.map(i => ({ ...i, date: moment.tz(i.date, "America/New_York") })) : [];
      this.newNotificationCount = res.unreadNotificationCount >= 0 ? res.unreadNotificationCount : this.notifications.filter(i => !i.read).length;
      this.fetchedIntialNotifications = true;
      if (this.newNotificationCount)
        this.accountService.showNotifsCount = true;
      else
        this.accountService.showNotifsCount = false;
    }, error => {

      this.accountService.showNotifsCount = false;
      this.notifications = [];
      this.newNotificationCount = 0;
      this.fetchedIntialNotifications = true;
    });
    if (!silentSync) {
      this.accountService.getUnreadFlyNotifications().subscribe((res) => {
        res = JSON.parse(res);
        res.forEach((element: any) => {
          const flyNotificationRef = this.nzNotificationService.info(element.title, this.sanitizeUrl(element.body) as string, { nzClass: 'fly-notification', nzDuration: 10000 });
           // Set the onClose event handler
           flyNotificationRef.onClose.subscribe((b) => {
            console.log('Notification closed!', element._id);
            b && this.markNotificationRead(element._id)
          });
        });
      }, error => {
      });
    }
  }

  notificationClickHandler(notification) {
    if (notification.body.length <= 150 && !notification.read) {
      this.markNotificationRead(notification._id);
    } else {
      // do nothing
    }
  }

  markNotificationRead(id: string) {
    this.accountService.markNotificationRead(id).subscribe((res) => {
      this.fetchNotifications(true);
    }, error => {
      //
    });
  }

  signout() {
    if (notificationsTimerRef) {
      clearInterval(notificationsTimerRef);
    }
    this.callLogout.emit();


    this.authService.signout();



  }
  changePassword() {
    this.router.navigate(['/dashboard/changepassword']);
  }
  NavigateToAccountSettings() {
    this.router.navigate(['/dashboard/account-settings']);
  }

  navigateToCreditReport() {
    this.router.navigate(["/dashboard/credit-report"]);
  }

  handleChangeAccount(): void {
    this.isVisible = true;
  }

}
