<section id="login_page" style="height:100%" class="pwd_page auth_page">
    <div class="row no-gutters">
        <div class="col left_maincol  text-white leftPanel">
            <div class="left_col">

                <a class="navbar-brand logohead" href="/">
                    <!-- <img src="/assets/logo_white.png" width="35.3" height="40" class="d-inline-block align-top" alt="">
                    <span class="ml-3 ">Revenued</span> -->
                    <img src="/assets/images/logo/revenuedLogoNew.svg" width="200" height="40"
                        class="d-inline-block align-top" alt="">
                </a>

                <h1 class="title">{{landingPageDescription}}</h1>
                <!-- <p class="data">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, cupiditate?</p> -->
            </div>
            <footer>
                <nav class="navbar navbar-expand-lg px-0 ">
                    <ul class="navbar-nav text-uppercase">
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.revenued.com/about/" target="_blank">About
                                Revenued</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.revenued.com/contact/" target="_blank">Contact us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.revenued.com/revenued-cardholder-agreement/"
                                target="_blank">Card user agreement</a>
                        </li>
                    </ul>
                </nav>
            </footer>
        </div>
        <div class="col d-flex justify-content-center right_maincol">

            <div class="right_col">
                <div class="right_col_head">
                    <div class="d-flex text-center justify-content-start">
                        <h3 class="authPageTitle" *ngIf="typeofPasswordUpdate==='verifyEmail'">Create Password</h3>
                        <h3 class="authPageTitle" *ngIf="typeofPasswordUpdate==='passwordReset'">Reset Password</h3>
                    </div>
                </div>

                <form [formGroup]="createPasswordForm" id="form" (ngSubmit)="setPassword()">
                    <div>
                        <div class="p-2 formFieldInput">
                            <label class="" for="password" [class.field-focus]="isPwdFocussed">Password <span
                                    class="text-danger">*</span></label>

                            <div class="input-group">

                                <input formControlName="newPassword" [type]="fieldTextType ? 'text' : 'password'"
                                    [readonly]="loader" (focus)="pwdOnFocus()" (focusout)="pwdOnFocusOut()"
                                    placeholder="Enter Password" class="form-control pwd-input">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="fa mouse-pointerz" [ngClass]="{
                                          'fa-eye-slash': !fieldTextType,
                                          'fa-eye': fieldTextType
                                        }" (click)="toggleFieldTextType()"></i>
                                    </span>
                                </div>
                            </div>

                            <div *ngIf="newPassword.touched && newPassword.errors && newPassword.errors.required"
                                class="text-danger">*Password is mandatory</div>
                            <div *ngIf="newPassword.errors && newPassword.errors.pattern" class="text-danger">
                                <div>Password must contain at least 8 Alphanumeric Characters with at least 1 special, 1
                                    uppercase, 1 lower case, 1 numeric character</div>
                            </div>
                        </div>
                        <div class="p-2 formFieldInput">
                            <label class="" for="confirmpassword" (keyUp)="0"
                                [class.field-focus]="isConfirmPwdFocussed">Confirm
                                Password <span class="text-danger">*</span></label>


                            <div class="input-group">
                                <input type="password" formControlName="confirmPassword" [readonly]="loader"
                                    [type]="fieldTextType1 ? 'text' : 'password'" class="form-control pwd-input"
                                    placeholder="Enter Password" (focus)="confirmpwdOnFocus()"
                                    (focusout)="confirmpwdOnFocusOut()" (blur)="arePasswordsEqual()">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="fa mouse-pointerz" [ngClass]="{
                                          'fa-eye-slash': !fieldTextType1,
                                          'fa-eye': fieldTextType1
                                        }" (click)="toggleFieldTextType1()"></i>
                                    </span>
                                </div>
                            </div>

                            <div *ngIf="confirmPassword.touched && confirmPassword.errors && confirmPassword.errors.required"
                                class="text-danger">*Confirm Password is mandatory</div>
                            <div *ngIf="confirmPassword.errors && confirmPassword.errors.pattern" class="text-danger">
                                <div>Password must contain at least 8 Alphanumeric Characters with at least 1 special, 1
                                    uppercase, 1 lower case, 1 numeric character</div>
                            </div>
                        </div>
                        <div class="text-danger" *ngIf="showPasswordNotError" class="text-danger">
                            <h6>New and Confirm Password don't Match - Make sure both passwords match.</h6>
                        </div>
                    </div>
                    <div class="text-center login_btn ">
                        <button type="submit" [disabled]="!createPasswordForm.valid"
                            class="btn btn-center login-button btn-responsive">
                            <ng-container *ngIf="!(loader && createPasswordForm.valid)">Set Password</ng-container>
                            <div *ngIf="(loader && createPasswordForm.valid)" class="loader xs-loader mx-auto"></div>
                        </button>
                    </div>
                </form>


                <div class="d-sm d-xl-none text-center mobile_signup">
                    <p clas="text-center"><a [routerLink]="loader?null:'#'"
                            [class.disabled-link]="loader"><span>Login</span></a></p>
                </div>
            </div>
        </div>
    </div>
</section>