import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ActivatedRoute
} from '@angular/router';
import { Observable, of, EMPTY, throwError } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { AuthenticationService } from '../core/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class VerifyEmailTokenResolverService implements Resolve<any> {
  constructor(private router: Router,private activatedRoute:ActivatedRoute,private authService:AuthenticationService) {

    // this.authService.getConfigs();


  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any  {
   
   setTimeout(() => {
    var email = route.queryParamMap.get('email');
    var passwordToken = route.queryParamMap.get('token');
    return this.authService.checkPasswordToken({"email":email,"passwordToken":passwordToken},route.routeConfig.path).pipe(
      take(1),
      mergeMap(res => {
        console.log("RESSSS::",res)
        return of(res)
      })
    )
   }, 5000);
   
    
  }
  
}