import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { LoginFormErrorModalComponent } from '../login-form-error-modal/login-form-error-modal.component';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css', '../sharedStyles.scss']
})
export class LoginComponent implements OnInit {
  loggedIn = false;
  isEmailFocussed = false;
  isPwdFocussed = false;
  loginError = false;
  modalRef: BsModalRef;
  fieldTextType: boolean;
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  constructor(public authService: AuthenticationService, public activatedRoute: ActivatedRoute, public router: Router, public fb: FormBuilder, private modalService: BsModalService) {

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    })

  }
  failedLogin = false;
  loginForm: FormGroup;
  landingPageDescription;
  ngOnInit() {
    this.landingPageDescription = localStorage.getItem('LANDING_PAGE_DESCRIPTION') || 'Revenued offers a FlexLine + Card built for all businesses';


    this.authService.loginError.subscribe((res) => {
      this.loggedIn = false;
      if (res === true) {
        const initialState = {
          title: 'Login Error',
          message: 'Invalid authentication'
          // message: !window.localStorage.getItem("loginError") ? 'Username and/or password incorrect' : window.localStorage.getItem("loginError")
        };

        window.localStorage.removeItem("loginError");
        this.authService.loginError.next(false);
        this.openModal(initialState);
      }
    })
  }
  emailOnFocus() {
    this.isEmailFocussed = true;
  }
  emailOnFocusOut() {
    this.isEmailFocussed = false;
  }
  pwdOnFocus() {
    this.isPwdFocussed = true;
  }
  pwdOnFocusOut() {
    this.isPwdFocussed = false;
  }
  get email() {
    return this.loginForm.get('email')
  }
  get password() {
    return this.loginForm.get('password')
  }
  login() {
    if (this.loggedIn) {
      return;
    }
    if (this.loginForm.valid) {
      this.loggedIn = true;
      // localStorage.clear(); //for hybrid app
      const params = this.activatedRoute.snapshot.queryParams;
      const redirectURL = params['redirectURL']
      this.authService.appLogin(this.loginForm, this.failedLogin, this.loggedIn, redirectURL);
    }
    else {
      const initialState = {
        title: 'Error',
        message: 'Required field(s) missing'
      };
      this.openModal(initialState);
    }
  }

  redirectToOtpLogin() {
    if (this.loginForm.value.email.trim()) {
      this.router.navigate(['/otplogin', this.loginForm.value.email.trim()]);
    } else {
      this.router.navigate(['/otplogin']);
    }
  }

  openModal(initialState) {

    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(LoginFormErrorModalComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';
  }
}
