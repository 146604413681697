import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './shared/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { CreatepasswordComponent } from './shared/createpassword/createpassword.component';
import { ForgotpasswordComponent } from './shared/forgotpassword/forgotpassword.component';
import { PricingCalComponent } from './shared/pricing-cal/pricing-cal.component';
import { VerifyEmailTokenResolverService } from './auth/verifyemailtoken.resolver';
import { TransactionhistoryIframeComponent } from './dashboard/transactionhistoryIframe//transactionhistory.component';
import { NoInternetComponent } from "./no-internet/no-internet.component";
import { ErrorsPageComponent } from "./errors-page/errors-page.component";
import { OtpLoginComponent } from './shared/otplogin/otplogin.component';
import { MFAOtpLoginComponent } from './shared/mfa-otplogin/mfa-otplogin.component';
import { TransactionsModalComponent } from './dashboard/transactionhistoryIframe/components/transactions-modal/transactions-modal.component';
import { PaymentsModalComponent } from './dashboard/transactionhistoryIframe/components/payments-modal/payments-modal.component';
import { MaintainanceComponent } from './maintainance/maintainance.component';
import { WalkthroughComponent } from './walkthrough/walkthrough.page';
import { UpgradeComponent } from './upgrade/upgrade.component';

const routes: Routes = [
  // { path: '', component: LoginComponent },
  { path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'login', component: LoginComponent },
  { path: 'getTransactionHistory/:oppId/:appname/:token', component: TransactionhistoryIframeComponent },
  { path: 'getTransactionHistory/:oppId/:appname/:token/:tab', component: TransactionhistoryIframeComponent },
  { path: 'getTransactionHistory/:oppId/:appname/:token/:tab/:id', component: TransactionhistoryIframeComponent },
  { path: 'getTransactionHistory/:oppId/:appname/:token/:tab/:id/:random', component: TransactionhistoryIframeComponent },
  { path: 'transaction-details/:oppId/:appname/:token/:tid', component: TransactionsModalComponent },
  { path: 'payment-details/:oppId/:appname/:token/:pid', component: PaymentsModalComponent },
  {
    path: 'verifyEmail', component: CreatepasswordComponent, resolve: {
      verifyEmail: VerifyEmailTokenResolverService
    }
  },
  {
    path: 'passwordReset', component: CreatepasswordComponent, resolve: {
      verifyEmail: VerifyEmailTokenResolverService
    }
  },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'otplogin', component: OtpLoginComponent },
  { path: 'otplogin/:email', component: OtpLoginComponent },
  { path: 'otplogin/:email/:is_email_sent', component: OtpLoginComponent },
  { path: 'mfaotplogin/:email/:is_email_sent', component: MFAOtpLoginComponent },
  {
    path: 'pricing',
    component: PricingCalComponent
  },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: "error", component: ErrorsPageComponent },
  { path: "error/:code", component: ErrorsPageComponent },
  { path: "no-internet", component: NoInternetComponent },
  { path: "maintainance", component: MaintainanceComponent },
  
  { path: "upgrade", component: UpgradeComponent },
  { path: "walkthrough", component: WalkthroughComponent },
  {
    path: '**',
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
