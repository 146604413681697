import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import moment from 'moment-business-days'; //'moment'
import { AccountService } from 'src/app/core/account.service';


export interface PayNowElement {
  days: number;
  sumOfDallies: number;
  remainingBalance: number;
  payNowBalance: number;
  totalPaid: number;
  weeksUntilPayNow: number;
  totalCostCapital: number;
  totalSavings: number;
  factor: String;
  date: String;
}

var payNowRows: PayNowElement[] = [];

@Component({
  selector: 'app-pricing-cal',
  templateUrl: './pricing-cal.component.html',
  styleUrls: ['./pricing-cal.component.css', '../../dashboard/prepay-cal/prepay-cal.component.css']
})
export class PricingCalComponent implements OnInit {
  searchText: any;
  totalCount = -1;
  Closed = -1;
  Inprogress = -1;
  Open = -1;
  invalidOppId = false;
  selectedOption = "daily";
  weeklyPayment = 0;
  startingBalance = 0;
  dailyPayment = 0;
  graceWindow = 22;
  repoBase = 1;
  postGraceRepoBase = 1;
  postGracePremium = 1.085;
  holidayList: any;
  term: any;
  factor;
  amount: any;
  configs = {};
  todayDate = new Date();
  maxDate = new Date('12/31/2025');
  selectedDate = new Date();
  // dataSource;
  payNowRows;
  forminput = { term: '', factor: '' };

  displayedColumns: string[] = [
    'bizDays',
    'bizDate',
    'sumDallies',
    'pnb',
    'remainBal',
    'totalPaid',
    'totCostCap',
    'factor',
    'totSavings',
  ];

  constructor(public router: Router, private activatedRoute: ActivatedRoute, private accountService: AccountService,) { }

  ngOnInit(): void {
    this.selectedOption = 'daily';
    let oppId = '';
    this.activatedRoute.queryParams.subscribe(
      (params: any) => {
        if (!params || !params.oppId) {
          this.invalidOppId = true;
        }
        oppId = params.oppId;
      }
    )

    const graceWindowDays = localStorage.getItem('Grace_Window_Days');
    if (graceWindowDays && !isNaN(Number(graceWindowDays))) {
      this.graceWindow = parseInt(graceWindowDays);
    } else {
      this.graceWindow = 22; // Default value
    }

    const postGracePremiumValue = localStorage.getItem('Post_Grace_Premium');
    if (postGracePremiumValue && !isNaN(Number(postGracePremiumValue))) {
      this.postGracePremium = parseFloat(postGracePremiumValue);
    } else {
      this.postGracePremium = 1.085; // Default value
    }

    const holidayListArray = "01/01/2021,01/01/2022,01/01/2023,01/01/2024,01/01/2025,18/01/2021,17/01/2022,16/01/2023,15/01/2024,20/01/2025,15/02/2021,21/02/2022,20/02/2023,19/02/2024,17/02/2025,31/05/2021,30/05/2022,29/05/2023,27/05/2024,26/05/2025,19/06/2021,19/06/2022,19/06/2023,19/06/2024,19/06/2025,04/07/2021,04/07/2022,04/07/2023,04/07/2024,04/07/2025,06/09/2021,05/09/2022,04/09/2023,02/09/2024,01/09/2025,11/10/2021,10/10/2022,09/10/2023,14/10/2024,13/10/2025,11/11/2021,11/11/2022,11/11/2023,11/11/2024,11/11/2025,25/11/2021,24/11/2022,23/11/2023,28/11/2024,27/11/2025,25/12/2021,25/12/2022,25/12/2023,25/12/2024,25/12/2025".split(',');

    // let oldTerm = !localStorage.getItem("prepayCalTerm") ? null : parseInt(localStorage.getItem("prepayCalTerm"));
    // let oldFactor = !localStorage.getItem("prepayCalFactor") ? null : parseFloat(localStorage.getItem("prepayCalFactor"));
    // let oldAmount = !localStorage.getItem("prepayCalAmount") ? null : parseFloat(localStorage.getItem("prepayCalAmount"));
    // if (true) {
    this.accountService.getTermAndFactorDirect(oppId).subscribe((res) => {
      try {
        res = JSON.parse(res);
        this.term = parseInt(res.term);
        this.selectedOption = res.collSchedType ? res.collSchedType?.toLowerCase() : 'daily'
        this.factor = parseFloat(res.factor);
        // localStorage.setItem("prepayCalTerm", this.term);
        // localStorage.setItem("collSchedType", this.selectedOption);
        // localStorage.setItem("prepayCalFactor", this.factor);
      } catch (e) {
        this.invalidOppId = true;
      }
    }, (error) => {
      this.term = 140;
      this.factor = 1.39;
      // localStorage.setItem("prepayCalTerm", this.term);
      // localStorage.setItem("prepayCalFactor", this.factor);
      // localStorage.setItem("collSchedType", this.selectedOption);
      this.invalidOppId = true;
    });
    // } else {
    //   this.term = oldTerm;
    //   this.factor = oldFactor;
    //   if (oldAmount) {
    //     this.amount = oldAmount;
    //     this.selectedDate = !localStorage.getItem("prepayCalDate") ? new Date() : new Date(localStorage.getItem("prepayCalDate"));
    //     setTimeout(() => {
    //       this.calculateTable("");
    //     }, 0);
    //   }
    // }
    this.holidayList = holidayListArray.map((d: string) => moment(d, 'DD/MM/YYYY'));

    moment.updateLocale('us', {
      holidays: holidayListArray,
      holidayFormat: 'DD/MM/YYYY',
    });
  }

  calculateTable(filterValue: string): void {
    if (
      this.term &&
      this.factor &&
      this.amount &&
      !isNaN(this.term) &&
      !isNaN(this.factor) &&
      !isNaN(this.amount) &&
      this.term > 0 &&
      this.factor > 0 &&
      this.amount > 0 &&
      this.selectedDate
    ) {
      console.log(this.term);
      console.log(this.factor);
      console.log(this.amount);
      // localStorage.setItem("prepayCalAmount", this.amount);
      // localStorage.setItem("prepayCalDate", moment(this.selectedDate).toString());
      this.repoBase = 1 / this.factor;
      this.postGraceRepoBase = this.postGracePremium / this.factor;

      console.log(this.repoBase);
      var dayIncrement = 5;
      this.startingBalance = this.amount * this.factor;
      this.dailyPayment = this.startingBalance / this.term;
      this.weeklyPayment = this.dailyPayment * 5;
      payNowRows = [];

      for (let i = 5; i <= this.term; i += 5) {
        console.log('push');
        let dailyTally = i * this.dailyPayment;
        let remainBal = this.startingBalance - dailyTally;
        let payNow =
          i > this.graceWindow ? remainBal * this.postGraceRepoBase : remainBal * this.repoBase;
        let totalPaid = payNow + dailyTally;
        let totalCostCapital = totalPaid - this.amount;
        let totalSavings = remainBal - payNow;
        payNowRows.push({
          days: i,
          date: moment(this.selectedDate).businessAdd(i).toString(),
          sumOfDallies: dailyTally,
          remainingBalance: remainBal,
          payNowBalance: payNow,
          totalPaid: totalPaid,
          weeksUntilPayNow: 1,
          totalCostCapital: totalCostCapital,
          totalSavings: totalSavings,
          factor: (1 + totalCostCapital / this.amount).toFixed(2),
        });
      }
      this.payNowRows = [...payNowRows]
    } else {
      this.payNowRows = [];
    }
  }

  goToCashDraw() {
    this.router.navigate(['/dashboard/cash-draw']);
  }

  isLoggedIn(): boolean {
    try {
      if (localStorage.getItem('login_ts') && localStorage.getItem('token')) {
        return (new Date().getTime() - parseInt(localStorage.getItem('login_ts'))) / 1000 < JSON.parse(localStorage.getItem('token')).expires_in
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  onOptionChanged(event: any) {
    this.selectedOption = event.value;
  }

  weekendsDatesFilter = (d: Date): boolean => {
    /* Prevent Saturday and Sunday for select. */
    return (
      d && moment(d).isBusinessDay() && !this.holidayList.find((h: any) => moment(d).isSame(moment(h)))
    );
  };
}
