import { Injectable } from "@angular/core";
import {
  HttpHeaders,
  HttpClient
} from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})

export class DataDogLoggerService {
  constructor(private http: HttpClient) {
  }

  logDataDogEvent(logLevel, message, source) {
    
    var idToken = JSON.parse(window.localStorage.getItem("token")).id_token;
    const headers = new HttpHeaders()
    .append("appname",environment.appName)
    .append("Authorization", "Bearer " + idToken);
    let data = {
      logLevel: logLevel,
      message: message,
      source: source,
      platform:  (window.localStorage.getItem('platform') || 'browser') //ionic_platform
    }
    return this.http.post(`${environment.baseUrl}v1/logging/logDataDogEvent`, data, { headers });
  }



}
