<div class="line-header-loader" *ngIf="this.accountService.globalDataLoader">
  <div class="line-header-loader__element"></div>
</div>
<section id="main_logo" [ngClass]="divbg" class="d-none d-xl-block d-md-block">
  <div class="container">

    <nav class="navbar navbar-expand-lg p-0">
      <div class="navbar-brand d-inline">
        <a routerLink="/dashboard">
          <img src="/assets/images/logo/revenuedLogo.svg" class="img-fluid d-inline" alt="Revenued Logo" />
        </a>
      </div>
      <app-header-right class="mob-display-none headerRight" [legalName]="legalName"></app-header-right>
    </nav>
  </div>
</section>
