<div class="profile-header" style="width: 100%">
  <!-- <div class="header-right-container rolloverBtn submitButton">
    <button nz-button (click)="rolloverModalToggle()">Apply For Rollover</button>
  </div>
  <nz-modal [(nzVisible)]="isRolloverVisible" nzTitle="Rollover" (nzOnCancel)="rolloverCancel()" (nzOnOk)="rolloverOk()"
    nzOkText="Apply" nzClassName="rolloverModal">
    <ng-container *nzModalContent>
      <p class="main">
        You are requesting a Collection transaction and authorizing a one-time debit of the amount shown above
        directly from your business bank account. You are also applying for a rollover with the terms shown above. Once
        confirmed, you'll receive a decision in 1-2 business days. Please review the Collection amount, new daily
        remittance and terms and click submit to confirm the transaction. By confirming, Merchant agrees to sell a
        portion (the Purchased Amount) of
        future revenue stream to Revenued. There is no interest rate or payment schedule and no time period during
        which the Purchased Amount must be collected. Merchant recognizes and certifies the accuracy of Revenued's
        good faith estimate of Merchant's monthly receipt and the Daily Remittance shown above and authorizes
        Revenued to debit an amount equal to the Remittance on each business day until such time as Revenued
        receives payment in full of the Purchased Amount. By confirming, Merchant should review these terms
        carefully and contact Revenued with any corrections.
      </p>
      <p class="note">
        By performing a Rollover, you'll be able to lower your daily payment amount by consolidating any outstanding
        advances into one total payment amount. To see how a Rollover can change your current payment balance, simply
        click to apply for a rollover when submitting your Collection request. If confirmed, you'll receive a decision
        in 1-2 business days
      </p>

    </ng-container>
  </nz-modal> -->

  <div class="header-right-container notification-bell-icon">
    <a nz-popover nzPopoverTrigger="click" [(nzPopoverVisible)]="notificationsPopOverVisible"
      [nzPopoverOverlayClassName]="notifications" [nzPopoverContent]="notificationsContentTemplate"
      class="notification-icon" role="button" data-toggle="dropdown" id="notificationsNavBarPopup" aria-haspopup="true">
      <span *ngIf="newNotificationCount > 0" class="badge badge-danger ml-2">{{newNotificationCount}}</span>
      <i class="fas fa-bell pt-4"></i>
    </a>
  </div>
  <ng-template #notificationsFooterContentTemplate>
    <button nz-button nzType="primary" (click)="handleMoreInfoClose()">close</button>
  </ng-template>
  <ng-template #notificationsContentTemplate>
    <div *ngIf="fetchedIntialNotifications" class="notifications-container">
      <div class="popup-header">
        <div class="m-3 font-weight-bold" style="color: #0040BA; font-size: 18px; display: inline">
          Notifications
        </div>
        <div class="m-3" style="font-size: 16px; background: red; color: white; display: inline"
          *ngIf="newNotificationCount > 0">
          <span class="p-3">{{newNotificationCount}} New</span>
        </div>
      </div>
      <div class="notification-list" (click)="notificationClickHandler(notifi)" *ngFor="let notifi of notifications"
        style="overflow-y: auto">
        <span *ngIf="notifi.read === false" class="unread-notification-badge"></span>
        <li class="flex-column">
          <div class="font-weight-bold">{{notifi.title}}</div>
          <div class="mt-2" style="color: rgba(0, 18, 43, 0.5);">
            <span [innerHTML]="notifi.body" *ngIf="notifi.body && notifi.body.length <= 150">
              <!-- {{notifi.body}} -->
            </span>
            <p *ngIf="notifi.body && notifi.body.length > 150">
              <span [innerHTML]="notifi.body.substr(0, 120)" class="xl-notification">
              </span>
              <!-- {{notifi.body.substr(0, 120) + "..."}} -->
              <span class="m-3 font-weight-bold view-more-of-notification" (click)="viewMoreHandler(notifi);"
                style="color: #0040BA; cursor: pointer; display: inline; margin-left: 0 !important;">
                ... View more
              </span>
            </p>
          </div>
          <div class="mt-2">{{notifi.date | date:'short':'UTC -6'}}</div>
        </li>
      </div>
      <hr />
      <div>
        <div *ngIf="notifications && notifications.length > 0 " class="m-3 font-weight-bold"
          (click)="goToNotifications()" id="view-all-notifications"
          style="color: #0040BA; font-size: 16px; margin: 10px 0; cursor: pointer; display: inline;">
          View all
        </div>
      </div>
      <div class="notification-list" *ngIf="!notifications || notifications.length === 0 " style="overflow-y: auto">
        <li class="flex-column">
          <nz-empty></nz-empty>
        </li>
      </div>
    </div>
  </ng-template>
  <ng-template #viewMoreInfoHeader>
    <div class="font-weight-bold" style="color: rgba(0, 18, 43, 0.5);">
      {{showMoreNotificationInfo?.title}}
    </div>
    <p style="color: #0040BA; font-size: 12px; padding-top: 10px; margin: 0;">
      {{showMoreNotificationInfo?.date | date:'short':'UTC -6'}}
    </p>
  </ng-template>
  <nz-modal [nzTitle]="viewMoreInfoHeader" [(nzVisible)]="showMoreNotificationInfoModal"
    [nzFooter]="notificationsFooterContentTemplate" (nzOnCancel)="handleMoreInfoClose()">
    <ng-container *nzModalContent>
      <p class="mt-2" style="color: rgba(0, 18, 43, 0.5); text-align: justify;"
        [innerHTML]="showMoreNotificationInfo?.body">
        <!-- {{showMoreNotificationInfo?.body}} -->
      </p>
    </ng-container>
  </nz-modal>
  <div class="ml-4 mt-lg-4 pull-right header-right-container persona-container">
    <div nz-button nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight" nzTrigger="click"
      class="mouse-pointerz">
      <i class="fas fa-user-circle profile-icon"></i>
    </div>
    <nz-dropdown-menu #menu1="nzDropdownMenu">
      <ul nz-menu style="z-index: 999999999;">
        <div class="popup-header">
          <div class="m-3 font-weight-bold" style="color: #0040BA; font-size: large; display: inline">
            {{ legalName }}
          </div>
        </div>
        <li nz-menu-item *ngIf="canSwitchAccounts" class="dropdown-item" (click)="handleChangeAccount()">
          <div class="font-weight-normal">
            <i class="fas fa-exchange-alt ml-2 menuIcon"></i>
            <span class="ml-3">Switch Account</span>
          </div>
        </li>
        <!-- <li nz-menu-item class="dropdown-item" (click)="navigateToCreditReport()">
          <div class="font-weight-normal">
            <i class="fas fa-star ml-2 menuIcon"></i>
            <span class="ml-3">Credit Report</span>
          </div>
        </li> -->
        <li nz-menu-item class="dropdown-item" (click)="NavigateToAccountSettings()">
          <div class="font-weight-normal">
            <i class="fas fa-cog ml-2 menuIcon"></i>
            <span class="ml-3">Account Settings</span>
          </div>
        </li>
        <li nz-menu-item class="dropdown-item sign-out" (click)="signout()">
          <div class="font-weight-normal">
            <i class="fas fa-sign-out-alt ml-2 menuIcon"></i>
            <span class="ml-3">Sign out</span>
          </div>
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
  <nz-modal [(nzVisible)]="isVisible" nzTitle="Switch Account" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()"
    [nzOkLoading]="isOkLoading">
    <div *nzModalContent>
      <p>Select an account to switch</p>
      <nz-select style="width: 100%;" nzShowSearch nzAllowClear nzPlaceHolder="Start typing or select from dropdown"
        [(ngModel)]="selectedAccount">
        <nz-option *ngFor="let client of clientEmails" [nzLabel]="client" [nzValue]="client"></nz-option>
      </nz-select>
    </div>
  </nz-modal>
</div>