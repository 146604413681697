<!-- *ngIf="!loggedIn" -->
<section id="login_page" style="height:100%">
    <div class="row no-gutters">
        <div class="col left_maincol  text-white "
            style="background:linear-gradient(72deg, #00122B 35.42%, #0040BA 100%);">
            <div class="left_col">

                <a class="navbar-brand logohead" href="/">
                    <img src="/assets/images/logo/revenuedLogoNew.svg" width="200" height="40"
                        class="d-inline-block align-top" alt="">
                </a>

                <h1>{{landingPageDescription}}</h1>
                <!-- <p class="data">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, cupiditate?</p> -->
            </div>
            <footer>
                <nav class="navbar navbar-expand-lg px-0 ">
                    <ul class="navbar-nav text-uppercase">
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.revenued.com/about/" target="_blank">About
                                Revenued</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.revenued.com/contact/" target="_blank">Contact us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.revenued.com/revenued-cardholder-agreement/"
                                target="_blank">Card User Agreement</a>
                        </li>
                    </ul>
                </nav>
            </footer>
        </div>

        <div class="col d-flex justify-content-center right_maincol">

            <div class="right_col">
                <div class="right_col_head">
                    <div class="d-flex text-center justify-content-center">
                        <h3>Verify Your <span>Identity</span></h3>
                    </div>
                </div>
                <hr>
                <div class="text-center justify-content-center">
                    <p>We've sent a text message to:</p>
                    <span><b>{{username_masked}}</b></span>
                </div>

                <form [formGroup]="verifyOtpForm" id="form1" (ngSubmit)="verifyEmailOtp()">
                    <div>
                        <p class="text-success text-center" *ngIf="!load">{{initialState.message}}</p>
                        <div class="pb-2">
                            <label class="pb-2" for="password" [class.field-focus]="isFieldFocussed">Enter OTP <span
                                    class="text-danger">*</span></label>



                            <div class="input-group">
                                <input formControlName="binding_code" (keypress)="numberOnly($event)" [readonly]="load"
                                    [type]="fieldTextType ? 'text' : 'password'" class="form-control p-1"
                                    placeholder="OTP" [(ngModel)]="binding_code" minlength="6" maxlength="6"
                                    (focus)="otpOnFocus()" (focusout)="otpOnFocusOut()">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="fa mouse-pointerz" [ngClass]="{
                                              'fa-eye-slash': !fieldTextType,
                                              'fa-eye': fieldTextType
                                            }" (click)="toggleFieldTextType()"></i>
                                    </span>
                                </div>
                            </div>


                            <div *ngIf="binding_code.touched && binding_code.errors && binding_code.errors.required"
                                class="text-danger">*Enter
                                valid
                                OTP
                            </div>


                        </div>
                        <!-- <div class="p-2">
                            <p>if you didn't receive the code?
                                <span *ngIf="timeLeft"> Resend in {{timeLeft}}s </span> <a
                                     (click)="loggedIn?null:sendEmailOtp(otpLoginForm,'true')" [class.disabled-link]="loggedIn"  *ngIf="!timeLeft"><u>Resend OTP</u>
                                </a>
                            </p>
                        </div> -->
                        <div class="text-center login_btn">
                            <button type="submit" class="btn btn-center login-button btn-responsive"
                                [disabled]="load  || !verifyOtpForm.valid">
                                <ng-container *ngIf="!(loggedIn && verifyOtpForm.valid)">SUBMIT</ng-container>
                                <div *ngIf="(loggedIn && verifyOtpForm.valid)" class="loader xs-loader mx-auto"></div>
                            </button>
                        </div>
                    </div>

                </form>


                <p class="text-center p-2">
                    <a [routerLink]="(load || loggedIn)?null:'/login'" [class.disabled-link]="(load || loggedIn)"
                        class="for_pass">Try another method?</a>
                </p>
            </div>
        </div>
    </div>
</section>

<!-- <div *ngIf="loggedIn && verifyOtpForm.valid">
    <div class="loader page-loader"></div>
</div> -->