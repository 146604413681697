
import { Component, OnInit, HostListener, isDevMode } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../environments/environment';
import { ConnectionService } from 'ng-connection-service';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { AlertController, MenuController } from '@ionic/angular';

import { Location } from '@angular/common';
import { BrowserLogger } from './core/browser-logger.service';
import { RumLogger } from './core/rum-logger.service';
import { AuthenticationService } from './core/authentication.service';
import { Platform } from '@ionic/angular';
import { Network } from '@awesome-cordova-plugins/network/ngx';
// import { InternetComponent } from './shared/internet/internet.component';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from "src/app/core/account.service";
import { Uid } from '@ionic-native/uid/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
// import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import * as $ from "jquery";
import { AuthService } from './auth/auth.service';
import { AppRate } from '@ionic-native/app-rate';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'revcarddev';
  status = 'ONLINE';
  isConnected = true;
  BrowserLoggerObj = null;
  RumLoggerObj = null;

  constructor(

    public router: Router, private connectionService: ConnectionService, private authService: AuthenticationService, private authenticationService: AuthService,
    private uid: Uid,
    private device: Device,
    private uniqueDeviceID: UniqueDeviceID,
    // private androidPermissions: AndroidPermissions,
    private toastr: ToastrService,
    private _location: Location,
    private statusBar: StatusBar,
    private appVersion: AppVersion,
    public accountService: AccountService,
    private theInAppBrowser: InAppBrowser,
    // private internet: InternetComponent,
    private network: Network,
    public alertController: AlertController,
    private platform: Platform,
  ) {

    $(document).ready(function () {
      $(document).click(function (event) {
        var click = $(event.target);
        var open = $(".navbar-collapse").hasClass("show");
        if (open === true && !click.hasClass("no-action")) {
          $(".navbar-toggler").click();
        }
      });
    });
    // console.log(this._location);
    this.initializeApp();


    // Initialize BackButton Eevent.
    this.backButton();

    if (!localStorage.getItem('environment')) {
      localStorage.setItem('environment', 'web');
    }
    if (!localStorage.getItem('platform')) {
      localStorage.setItem('platform', 'Web');
    }


    try {
      this.BrowserLoggerObj = new BrowserLogger();
      this.RumLoggerObj = new RumLogger();
      this.authService.RumLoggerObj$ = this.RumLoggerObj;


    } catch (error) {
      //do nothing
    }


  }
  backButton() {

    // console.log(this._location);
    const that = this;
    let lastTimeBackPress = 0;
    const timePeriodToExit = 2000;
    function onBackKeyDown(e) {
      e.preventDefault();
      e.stopPropagation();
      if (that._location.isCurrentPathEqualTo('/dashboard') || that._location.isCurrentPathEqualTo('/no-internet') || that._location.isCurrentPathEqualTo('/maintainance') || that._location.isCurrentPathEqualTo('/login') || that._location.isCurrentPathEqualTo('/') || that._location.isCurrentPathEqualTo('/upgrade')) {
        if (new Date().getTime() - lastTimeBackPress < timePeriodToExit) {
          that.showExitConfirm();
        } else {
          that.presentToast();
          lastTimeBackPress = new Date().getTime();
        }
      } else {
        that._location.back();
      }


    }
    document.addEventListener('backbutton', onBackKeyDown, false);
  }

  presentToast() {

    this.toastr.info("Press again to exit");
  }
  ngOnInit(): void {



    console.log("isDevMode ", isDevMode())
    if (!isDevMode()) {
      console.log = () => { };
      console.error = () => { };
      console.warn = () => { };
      console.info = () => { };
      console.debug = () => { };
      console.trace = () => { };
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.accountService.globalDataLoader = false;
      window.scrollTo(0, 0)
    });


    if (localStorage.getItem('email') && JSON.parse(window.localStorage.getItem("token")).access_token)
      this.authService.fetchUserCache(localStorage.getItem('email'), JSON.parse(window.localStorage.getItem("token")).access_token)
  }
  public isDesktop() {
    let platforms = this.platform.platforms();
    let isDesktop = true;
    if (this.platform.is('android')) {
      isDesktop = false;
    } else if (this.platform.is('ios')) {
      isDesktop = false;
    } else {
      isDesktop = true;
    }

    return isDesktop;
  }

  // network_check() {
  //   // watch network for a disconnection
  //   const disconnectSubscription = this.network.onDisconnect().subscribe(() => {
  //     console.log('network was disconnected :-(');
  //     this.internet.goToNoInternet();
  //     //this.presentAlert();
  //   });

  //   // watch network for a connection
  //   const connectSubscription = this.network.onConnect().subscribe(() => {
  //     console.log('network connected!');
  //     this.internet.retry();
  //   });
  // }
  forceStoreDeviceFinger() {
    this.uniqueDeviceID.get()
      .then((uuid: any) => {
        // console.log(cordova.plugins.uid.IMEI);
        // console.log(this.device);

        if (this.platform.is('android')) {
          // console.log(uuid);
          try {

            console.log(this.uid);
          } catch (error) {

            console.log(error);
          }

        }
        // this.toastr.info("Device : " + uuid);
        localStorage.setItem('deviceFingerPrint', this.device.uuid);
        localStorage.setItem('deviceFingerPrintJson', JSON.stringify(this.device));

      })
      .catch((error: any) => {
        console.log(error)
        //this.toastr.warning("Unable to fetch device ID");
      });
  }
  // getPermission() {

  //   if (localStorage.getItem('environment') !== 'web') {
  //     this.androidPermissions.checkPermission(
  //       this.androidPermissions.PERMISSION.READ_PHONE_STATE
  //     ).then(res => {
  //       if (res.hasPermission) {
  //         this.forceStoreDeviceFinger();
  //       } else {
  //         this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_PHONE_STATE).then(res => {
  //           console.log(res);
  //           this.forceStoreDeviceFinger();
  //         }).catch(error => {
  //           this.toastr.warning("Insufficient permissions!");
  //         });
  //       }
  //     }).catch(error => {
  //       this.toastr.warning("Insufficient permissions!");
  //     });
  //   }

  // }
  // regNativeDeviceId() {
  //   this.platform.ready().then(() => {
  //     if (this.platform.is('android')) {
  //       this.getPermission();
  //     }

  //   });
  // }
  public checkInternetConnectionBool() {
    return this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      return this.isConnected;
    })
  }
  public checkInternetConnection() {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
        if (!this.authenticationService.isLoggedIn) {
          this.router.navigate(['/']);
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.router.navigate(['/no-internet']);
        this.status = "OFFLINE";
      }
    })
  }




  public checkAppSupportedVersions(deviceType, deviceVersion) {
    this.accountService.globalDataLoader = true;
    console.log(deviceType);
    console.log(deviceVersion);
    this.authService.checkAppSupportedVersions(deviceType).subscribe((res: any) => {
     
      console.log(res);
      this.accountService.globalDataLoader = false;
      if (res?.success) {
        if (res?.versions) {
          if (res?.versions.length) {
            let supportedVersions = res?.versions;
            if (!supportedVersions.includes(deviceVersion)) {
              this.router.navigate(['/upgrade']);
            }
          }
        }
      }
    },
      (err: any) => {
        this.accountService.globalDataLoader = false;
        console.log(err);

      }
    );


  }

  initializeApp() {
    try {
      this.authService.siteMetaData().then((res: any) => {
        console.log(res);
      },
        (err: any) => {
          console.log(err);
        }
      );

    } catch (error) {
      console.log(error);
    }

    this.platform.ready().then(() => {
      this.checkInternetConnection();
      this.statusBar.styleBlackTranslucent();
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString("#0040BA");

      // this.regNativeDeviceId();
      if (this.platform.is('cordova')) {
        if (!this.isDesktop()) {
          localStorage.setItem('environment', 'device');
          if (this.platform.is('android')) {
            localStorage.setItem('platform', 'Android');
          } else if (this.platform.is('ios')) {
            localStorage.setItem('platform', 'iOS');
          } else {
            localStorage.setItem('platform', 'Web');
          }
          //temp
          this.appVersion.getAppName().then(value => {
            localStorage.setItem('platform_app_name', value);
          }).catch(err => {
            console.log(err);
          });

          this.appVersion.getPackageName().then(value => {
            localStorage.setItem('platform_app_package_name', value);
          }).catch(err => {
            console.log(err);
          });

          this.appVersion.getVersionCode().then(value => {
            localStorage.setItem('platform_app_version_code', value.toString());
          }).catch(err => {
            console.log(err);
          });

          this.appVersion.getVersionNumber().then(value => {
            localStorage.setItem('platform_app_version_number', value);
            this.checkAppSupportedVersions(localStorage.getItem('platform'), value);
          }).catch(err => {
            console.log(err);
          });

          //temp


        } else {
          localStorage.setItem('environment', 'web');
          localStorage.setItem('platform', 'Web');
        }

        //Subscribe on resume i.e. foreground 
        this.platform.resume.subscribe(() => {
          // this.toastr.show("App Resumed!", "", { positionClass: 'toast-bottom-right' });
          if (localStorage.getItem('environment') !== 'web') {
            this.checkForMaintainanceMode();
          }

          if (window.localStorage.getItem("token")) {
            this.authService.isTokenIdValid().subscribe(
              (res) => {
                //this.router.navigate(['/dashboard'])
              },
              () => {
                // this.toastr.warning("Session Expired, Login Again!");
                window.localStorage.removeItem("token");
                this.router.navigate(['/login'])
                window.location.reload();
              }
            );
          }


        });



      } else {
        localStorage.setItem('environment', 'web');
        localStorage.setItem('platform', 'Web');
      }

      this.checkForMaintainanceMode();
    });




  }

  isShow: boolean;
  topPosToStartShowing = 100;
  @HostListener('window:scroll')
  checkScroll() {
    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= this.topPosToStartShowing) {
      // this.isShow = true;
      this.isShow = false;
    } else {
      this.isShow = false;
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  options: InAppBrowserOptions = {
    location: 'no',//Or 'no' 
    hidden: 'no', //Or  'yes'
    clearcache: 'yes',
    clearsessioncache: 'yes',
    zoom: 'no',//Android only ,shows browser zoom controls 
    hardwareback: 'no',
    mediaPlaybackRequiresUserAction: 'no',
    shouldPauseOnSuspend: 'no', //Android only 
    closebuttoncaption: 'Close', //iOS only
    disallowoverscroll: 'no', //iOS only 
    toolbar: 'yes', //iOS only 
    enableViewportScale: 'no', //iOS only 
    allowInlineMediaPlayback: 'no',//iOS only 
    presentationstyle: 'pagesheet',//iOS only 
    fullscreen: 'yes',//Windows only    
    hideurlbar: 'yes', // hide the url toolbar
  };
  public openWithSystemBrowser(url: string) {
    let target = "_self";
    let browser = this.theInAppBrowser.create(url, target, this.options);
    browser.insertCSS({ code: "body{background: #fff !important;" });
    browser.on('loadstop').subscribe(event => {
      browser.insertCSS({ code: "body{background: #fff !important;" });
    });
  }
  public checkForMaintainanceMode() {

    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.accountService.globalDataLoader = true;
        // this.spinner.show();
        this.authService.checkForMaintainanceMode().subscribe((res: any) => {
          // this.spinner.hide();
          this.accountService.globalDataLoader = false;

          if (res?.success) {
            // this.openWithSystemBrowser(`${environment.appUrl}`);
            this.redirectToMaintainancePage();
          } else {
            // this.router.navigate(['/']);
          }

        }
          ,
          (err: any) => {
            // this.spinner.hide();
            this.accountService.globalDataLoader = false;

            console.log(err);
            //  this.openWithSystemBrowser(`${environment.appUrl}`);
            //  this.redirectToMaintainancePage();
          }
        );
      }
      else {
        this.router.navigate(['/no-internet']);
        this.status = "OFFLINE";
      }



    });

  }

  public redirectToMaintainancePage() {
    if (this.checkInternetConnectionBool()) {
      this.router.navigate(['/maintainance']);
    }
  }

  showExitConfirm() {

    this.alertController
      .create({
        header: '',
        message: 'Do you want to close the app?',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Stay',
            role: 'cancel',
            handler: () => {
              console.log('Application exit prevented!');
            },
          },
          {
            text: 'Exit',
            handler: () => {
              navigator['app'].exitApp();
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

}
