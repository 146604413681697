<section id="trans_his">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="card border-0">
                    <div class="tab">
                        <button class="tablinks {{tabName==='transactions'?'active':''}}"
                            (click)="switchTab('transactions')">
                            Transactions
                        </button>
                        <button class="tablinks {{tabName==='payments'?'active':''}}" (click)="switchTab('payments')">
                            Payments
                        </button>
                    </div>
                    <div id="transactions" class="tabcontent" [style]="tabName==='transactions'?'display: block':'none'">
                        <app-transactions></app-transactions>
                    </div>
                    <div id="payments" class="tabcontent"  [style]="tabName==='payments'?'display: block':'none'">
                        <app-payments></app-payments>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div *ngIf="transactionsPlaceholderMessage">
    <div class="d-flex justify-content-center p-5 border-0">
        <b>{{transactionsPlaceholderMessage}}</b>
    </div>
</div>
<ng-template #invalidDateTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left"></h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Start Date cannot be greater than End Date
    </div>
</ng-template>