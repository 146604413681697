<ion-content>
  <ion-slides pager="true" dir="ltr" options="slideOpts">
    <ion-slide *ngFor="let slide of slideList" class="bg-dark" style="background-color:#2376b8 !important;">
      <div class="ion-padding">
        <img
          [src]="slide.image"
          alt="Slides"
          class="ion-margin-bottom slide-image animated fadeInDown slow"
        />
        <!-- <h3
          class="slide-title text-white animated fadeIn"
          [innerHTML]="slide.title"
        ></h3>-->
        <!-- <ion-text color="light">
          <p [innerHTML]="slide.title" class="animated fadeIn"></p>
        </ion-text>  -->

        <ion-button
          expand="full"
          shape="round"
          size="large"
          color="secondary"
          class="ion-margin-top"
          (click)="onSlideNext()"
        >
          Next
          <ion-icon name="arrow-forward-circle" slot="end"></ion-icon>
        </ion-button>
      </div>

      <!-- <div padding>

      </div> -->
    </ion-slide>
    <ion-slide class="bg-primary">
      <div class="ion-padding w100">
        <!-- <img
          src="assets/img/ccadvisor_logo.png"
          alt="foodIonic"
          class="slide-image animated fadeInDown slow"
        /> -->
        <!-- <h2 class="ion-margin-bottom slide-title text-white">
          Ready to Unlock?
        </h2> -->
        <!-- <div class="form-check text-white checkbox-container">
          <input type="checkbox" class="form-check-input" id="check1" name="option1" value="skip">
          <label class="form-check-label" for="check1">Skip Intro</label>
        </div> -->
        <label class="checkbox-container text-white">Skip Intro
          <input type="checkbox" checked="checked" id="skipIntroCheck">
          <span class="checkmark"></span>
        </label>
        <!-- <ion-button
          expand="full"
          shape="round"
          color="secondary"
          size="large"
          class="ion-margin-top"
          (click)="openLoginPage()"
          disabled
        >
          <div>
            Sign In / Sign Up
            <p style="margin: 0"><small>Coming Soon</small></p>
          </div>
        </ion-button> -->
        <ion-button
          expand="full"
          shape="round"
          size="large"
          color="dark"
          class="ion-margin-top"
          (click)="skipIntroCheck()"
        >
          <!-- routerLink="/home" -->
          Get Started
        </ion-button>
      </div>
    </ion-slide>
  </ion-slides>
</ion-content>
