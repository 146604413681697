export const environment = {
  production: false,
  buildname: 'its in production',
  baseUrl: 'https://api.revenued.com/',
  appUrl: 'https://app.revenued.com/',
  socketPort:8080,
  playstoreAppUrl:"https://play.google.com/store/apps/details?id=com.revenuedapp.www",
  appstoreAppUrl:"https://apps.apple.com/us/app/revenued/id6443660423",
  playstoreAppId:'com.revenuedapp.www',
  appstoreAppId:'id6443660423',
  datadog: true,
  appEnvName:'prod',
  appName: 'Revenued',
  REVENUED_MIDDLEWARE_TOKEN: 'dc17dfb39ed1b4879ada1124bf544a46'
};

export const httpInterceptorEnvironment = {
  errorHandlerRetryAttemt: 0,//by default retry once in future, fetch retry attempt # from request headers of api calls
  showInterceptorLoader: false,//keep it false for production
  bypassGlobalErrorHandler: true //i will have to remove this becuase this will disturb existing system  or implement after completing interceptor tasks
}
