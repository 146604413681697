import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupByProperty'
})
export class GroupByPropertyPipe implements PipeTransform {

  transform(value: Array<any>, property: string): Array<any> {
    if (!value) {
      return null;
    }
    const group = value.reduce((previous, current) => {
      const parts = property.split('.');
      let currentValue: any;
      parts.forEach(part => {
        currentValue = currentValue ? currentValue[part] : current[part];
      });
      // Stringify objects for comparison
      currentValue = typeof currentValue === 'object' ? JSON.stringify(currentValue) : currentValue;
      if (!previous[currentValue]) {
        previous[currentValue] = [current];
      } else {
        previous[currentValue].push(current);
      }
      return previous;
    }, {});
    return Object.keys(group).map(key => ({ key, value: group[key] }));
  }

}
