<div class="modal-header">
    <h4 class="modal-title">Transaction Details</h4>
    <button type="button" class="btn-close" (click)="activeModal.dismiss('Cross click')"><span
            aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body">
    <div class="container">
        <div id="paymentModalContent" *ngIf="this.trxnDetails">
            <h5> {{this.trxnDetails?.RevTrxnDetail?.pymt?.RevPymtDetail[0]?.tid?('Transaction ID:
                '+this.trxnDetails?.RevTrxnDetail?.pymt?.RevPymtDetail[0]?.tid):''}}</h5>

            <ng-container *ngIf="this.trxnDetails?.RevTrxnDetail?.trxn">
                <table class="table">
                    <tr>
                        <td class="border-0" style="min-width:400px !important;padding: 0% !important;">
                            <p class="font-weight-bold">
                                {{this.trxnDetails?.RevTrxnDetail?.trxn['cpty'].name}}</p>
                        </td>
                        <td class="border-0" style="min-width:400px !important;padding: 0% !important;">
                            <p class="font-weight-bold">
                                {{
                                this.accountService.getFormattedAmount(this.trxnDetails?.RevTrxnDetail?.trxn.amount)
                                }}</p>
                        </td>
                    </tr>
                    <tr>

                        <td class="border-0" style="min-width:400px !important;padding: 0% !important;">
                            {{this.trxnDetails?.RevTrxnDetail?.trxn?.sysDate | date}}
                        </td>

                        <td class="border-0" style="min-width:400px !important;padding: 0% !important;">
                            <p class="d-flex">
                                {{transactionStatus[this.trxnDetails?.RevTrxnDetail?.trxn?.status]}}
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td class="border-0" colspan="2" style="min-width:400px !important;padding: 0% !important;">
                            <strong> Total Number of Payments:
                                {{this.trxnDetails?.RevTrxnDetail?.pymt?.RevPymtDetail.length}} </strong>
                        </td>
                    </tr>

                </table>
                <hr>
            </ng-container>



            <ng-container *ngIf="this.trxnDetails?.RevTrxnDetail?.pymt?.RevPymtDetail.length">
                <h5><u>Payments allocated:</u>
                </h5>
                <div class="form-group mr-1 p-2">
                    <div class="icon-addon addon-lg">
                        <label for="search" class="fa fa-search" rel="tooltip" title="Search..."
                            *ngIf="!searchItem"></label>
                        <input type="text" id="search" placeholder="Search..." class="form-control search-box"
                            [(ngModel)]="searchItem">
                        <label class="fa fa-close mouse-pointerz" (click)="searchItem=null" *ngIf="searchItem"></label>
                    </div>

                </div>
            </ng-container>


            <div class="paymentModalCard card shadow mb-3  {{!this.accountService.checkIsVisible(item)?'d-none':''}}"
                *ngFor="let item of this.trxnDetails?.RevTrxnDetail?.pymt?.RevPymtDetail  | filter:searchItem">


                <table class="table">
                    <tr>
                        <td style="max-width: 200px; width: 90%; border-color: transparent;color: #2373b0; font-weight: bold;"
                            valign="top">
                            <p class="m-0">
                                <!-- (click)="openPaymentsModal(item['pid'])" -->
                                Payment ID: <a class="mouse-pointerz"
                                    (click)="openPaymentModal(item['pid'],item)">{{item['pid']}}</a>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td style="border-color: transparent; padding-top: 0;">
                            <p class="m-0">
                                <span class="m-0 paymentStatus">
                                    <span [ngClass]="indicator? 'success' : 'fail' " class="indicator"></span>
                                    {{this.accountService.getTitleForHistory(item)}}
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="pt-0 border-0">
                            <hr class="m-0" />
                            <table class="table">
                                <tr>
                                    <td style="width: 50%;border-color: transparent;padding-right: 2px;" valign="top">
                                        <p class="m-0 mutedText">Date</p>
                                        <p class="m-0">{{item?.sysDate|date}}
                                        </p>
                                    </td>
                                    <td style="width: 50%;border-color: transparent; padding-left:2px;" valign="top">
                                        <p class="m-0 mutedText">Discount Savings</p>
                                        <p class="m-0">{{
                                            this.accountService.getFormattedAmount(item?.totSaveAmt )
                                            }}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="width: 100%; border-color: transparent;padding-right: 2px;"
                                        valign="top">
                                        <p class="m-0 mutedText">Payment Allocation</p>
                                        <p class="m-0">{{

                                            this.accountService.getFormattedAmount(item?.amount )
                                            }}</p>
                                    </td>
                                </tr>

                                <tr>
                                    <td style="width: 50%;border-color: transparent;padding-right: 2px;" valign="top">
                                        <p class="m-0 mutedText"><small>Starting Bal to Done</small> </p>
                                        <p class="m-0">{{
                                            this.accountService.getFormattedAmount(item?.paybackAmount)
                                            }}</p>
                                    </td>
                                    <td style="width: 50%;border-color: transparent; padding-left:2px;" valign="top">
                                        <p class="m-0 mutedText"><small>End Bal. to Done</small> </p>
                                        <p class="m-0">{{
                                            this.accountService.getFormattedAmount(item?.balToDone )
                                            }}</p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
            <div *ngIf="this.trxnDetails?.RevTrxnDetail?.pymt?.RevPymtDetail" class="text-center">
                No more payment(s) to display
            </div>
            <div *ngIf="!this.trxnDetails?.RevTrxnDetail?.pymt?.RevPymtDetail" class="text-center">
                <span *ngIf="isTransactionDetailsLoaded">
                    No Payment(s) to display
                </span>
                <span *ngIf="!isTransactionDetailsLoaded">
                    <div class="loader xs-loader mx-auto"></div>
                    Retrieving Payment(s)...
                </span>
            </div>
        </div>

    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-lg btn-outline-dark w-100"
        (click)="activeModal.close('Close click')">Close</button>
</div>