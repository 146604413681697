import { Component, ElementRef, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ConnectionService } from "ng-connection-service";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../auth/auth.service";

@Component({
  selector: "app-no-internet",
  templateUrl: "./no-internet.component.html",
  styleUrls: ["./no-internet.component.css"],
})
export class NoInternetComponent implements OnInit {
  constructor(private router: Router, private elementRef: ElementRef,
    private authenticationService: AuthService,
    private toastr: ToastrService,
    private connectionService: ConnectionService,

  ) {
    this.elementRef.nativeElement.ownerDocument
      .body.style.backgroundColor = '#fff';
  }

  ngOnInit(): void { }
  isRetrying=false;
  retry() {
    // this.router.navigate(["/"]).then((res) => {
    //   //localStorage.clear();
    // });

    setTimeout(() => {
      this.isRetrying=false;
      
    }, 5000);
    this.isRetrying=true;
    // this.toastr.show("Retrying...");
    this.connectionService.monitor().subscribe(isConnected => {
      if (isConnected) {
        if (!this.authenticationService.isLoggedIn) {
          this.router.navigate(['/']);
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        //do nothing
      }
    })

  }
}
