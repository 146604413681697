import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BsDropdownConfig } from "ngx-bootstrap/dropdown";
import { AuthenticationService } from "../../core/authentication.service";
import { AccountService } from "../../core/account.service";
import { RouterEvent, Router } from "@angular/router";
import { filter } from "rxjs/operators";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  providers: [
    {
      provide: BsDropdownConfig,
      useValue: { isAnimated: true, autoClose: true },
    },
  ],
})
export class HeaderComponent implements OnInit {
  logInStatus = false;
  selectedAccount = "";
  clientEmails: any;
  canSwitchAccounts = false;
  isVisible = false;
  isOkLoading = false;
  accountLast: any;
  legalName: any;
  @Output() callLogout = new EventEmitter();
  constructor(
    public authService: AuthenticationService,
    public accountService: AccountService,
    public router: Router
  ) {
    // this.authService.loggedInEvent.subscribe((logInStatus) => {
    //   this.logInStatus = logInStatus;
    // })
    router.events
      .pipe(filter((e) => e instanceof RouterEvent))
      .subscribe((e) => {
        if (
          e["url"] == "/dashboard/transactionhistory" ||
          e["url"] == "/dashboard/payment-calendar"
        ) {
          this.divbg = "brandBlueBackground";
        } else {
          // this.divbg = "bluebackground1";
          this.divbg = "brandBlueBackground";
        }
      });
  }
  // divbg = 'bluebackground1';
  divbg = 'brandBlueBackground';

  ngOnInit() {
    //
  }

  isMobile() {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
      (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)))
  }

}
