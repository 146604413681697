import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlertController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';

import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.css']
})
export class UpgradeComponent implements OnInit {
 
  platform= localStorage.getItem('platform');
  constructor(

    private theInAppBrowser: InAppBrowser,
    private toastr: ToastrService,
    public alertController: AlertController) { }

  ngOnInit(): void {
  }

  goToDownload() {
    let url = "https://www.revenued.com";
    if (localStorage.getItem('platform') === 'Android') {
      url = environment.playstoreAppUrl;
    } else {
      url = environment.appstoreAppUrl;
    }
    this.openWithSystemBrowser(url);
    // this.toastr.show("Work In Progress...");
  }

  showExitConfirm() {
    this.alertController
      .create({
        header: '',
        message: 'Do you want to close the app?',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Stay',
            role: 'cancel',
            handler: () => {
              console.log('Application exit prevented!');
            },
          },
          {
            text: 'Exit',
            handler: () => {
              navigator['app'].exitApp();
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  options: InAppBrowserOptions = {
    location: 'no',//Or 'no' 
    hidden: 'no', //Or  'yes'
    clearcache: 'yes',
    clearsessioncache: 'yes',
    zoom: 'no',//Android only ,shows browser zoom controls 
    hardwareback: 'no',
    mediaPlaybackRequiresUserAction: 'no',
    shouldPauseOnSuspend: 'no', //Android only 
    closebuttoncaption: 'Close', //iOS only
    disallowoverscroll: 'no', //iOS only 
    toolbar: 'yes', //iOS only 
    enableViewportScale: 'no', //iOS only 
    allowInlineMediaPlayback: 'no',//iOS only 
    presentationstyle: 'pagesheet',//iOS only 
    fullscreen: 'yes',//Windows only    
    hideurlbar: 'yes', // hide the url toolbar
  };
  public openWithSystemBrowser(url: string) {
    let target = "_blank";
    let browser = this.theInAppBrowser.create(url, target, this.options);
    browser.insertCSS({ code: "body{background: #fff !important;" });
    browser.on('loadstop').subscribe(event => {
      browser.insertCSS({ code: "body{background: #fff !important;" });
    });
  }

}
