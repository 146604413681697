<!-- *ngIf="!loggedIn" -->

<section id="login_page" style="height:100%" class=" auth_page">
    <div class="row no-gutters">
        <div class="col left_maincol  text-white leftPanel">
            <div class="left_col">

                <a class="navbar-brand logohead">
                    <img src="/assets/images/logo/revenuedLogoNew.svg" width="200" height="40"
                        class="d-inline-block align-top" alt="">
                </a>

                <h1 class="title">{{landingPageDescription}}</h1>
                <!-- <p class="data">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, cupiditate?</p> -->
            </div>
            <footer>
                <nav class="navbar navbar-expand-lg px-0 ">
                    <ul class="navbar-nav text-uppercase">
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.revenued.com/about/" target="_blank">About
                                Revenued</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.revenued.com/contact/" target="_blank">Contact us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.revenued.com/revenued-cardholder-agreement/"
                                target="_blank">Card User Agreement</a>
                        </li>
                    </ul>
                </nav>
            </footer>
        </div>
        <div class="col d-flex justify-content-center right_maincol">

            <div class="right_col">
                <div class="right_col_head">
                    <div class="d-flex justify-content-start">
                        <h3 class="authPageTitle">
                            Welcome to <span>Revenued</span>
                        </h3>
                    </div>
                </div>

                <form [formGroup]="loginForm" id="form" (ngSubmit)="login()">
                    <div>
                        <div class="p-2 formFieldInput">
                            <label class="" for="email" [class.field-focus]="isEmailFocussed">Email Address <span
                                    class="text-danger">*</span></label>
                            <div class="input-group">
                                <input id="email" formControlName="email" type="email" class="form-control email-input"
                                    [readonly]="loggedIn" placeholder="User Email" (focus)="emailOnFocus()"
                                    (focusout)="emailOnFocusOut()" [ngClass]="
                                        ((loginForm.get('email').invalid && 
                                        loginForm.get('email').touched && 
                                        loginForm.get('email').errors?.required)||
                                        (loginForm.get('email').touched && 
                                        loginForm.get('email').errors?.email)) ? 'invalid' : ''">
                                <div class="input-group-append">
                                    <span class="input-group-text" [ngClass]="
                                        ((loginForm.get('email').invalid && 
                                        loginForm.get('email').touched && 
                                        loginForm.get('email').errors?.required)||
                                        (loginForm.get('email').touched && 
                                        loginForm.get('email').errors?.email)) ? 'invalid' : ''">

                                        <i class="fa fa-envelope" aria-hidden="true" [ngClass]="
                                        ((loginForm.get('email').invalid && 
                                        loginForm.get('email').touched && 
                                        loginForm.get('email').errors?.required)||
                                        (loginForm.get('email').touched && 
                                        loginForm.get('email').errors?.email)) ? 'invalid' : ''"></i>
                                    </span>

                                </div>
                            </div>
                            <div
                                *ngIf="(loginForm.get('email').invalid && loginForm.get('email').touched) || loginForm.get('email').dirty">
                                <small class="text-danger" *ngIf="loginForm.get('email').errors?.required">*Email
                                    address
                                    is required</small>
                                <small class="text-danger" *ngIf="loginForm.get('email').errors?.email">Email address
                                    is invalid</small>
                            </div>


                        </div>

                        <div class="p-2 password formFieldInput">
                            <label class="" for="password" [class.field-focus]="isPwdFocussed">Password <span
                                    class="text-danger">*</span></label>

                            <div class="input-group">

                                <input id="password" formControlName="password"
                                    [type]="fieldTextType ? 'text' : 'password'" [readonly]="loggedIn"
                                    class="form-control pwd-input" placeholder="Enter Password" (focus)="pwdOnFocus()"
                                    (focusout)="pwdOnFocusOut()" [ngClass]="
                                        ((loginForm.get('password').invalid && 
                                        loginForm.get('password').touched && 
                                        loginForm.get('password').errors?.required)||
                                        (loginForm.get('password').touched && 
                                        loginForm.get('password').errors?.email)) ? 'invalid' : ''">
                                <div class="input-group-append">
                                    <span class="input-group-text" [ngClass]="
                                        ((loginForm.get('password').invalid && 
                                        loginForm.get('password').touched && 
                                        loginForm.get('password').errors?.required)||
                                        (loginForm.get('password').touched && 
                                        loginForm.get('password').errors?.email)) ? 'invalid' : ''">

                                        <i class="fa mouse-pointerz" tabindex="0" [ngClass]="{
                                          'fa-eye-slash': !fieldTextType,
                                          'fa-eye': fieldTextType
                                        }" (click)="toggleFieldTextType()"></i>
                                    </span>
                                </div>
                            </div>
                            <div
                                *ngIf="(loginForm.get('password').invalid && loginForm.get('password').touched) || loginForm.get('password').dirty">
                                <small class="text-danger" *ngIf="loginForm.get('password').errors?.required">*Password
                                    is required</small>

                            </div>


                        </div>

                        <div class="text-danger" *ngIf="failedLogin">
                            <h6>Username and Password mismatch</h6>
                        </div>

                    </div>
                    <p class="text-right "><a [routerLink]="loggedIn?null:'/forgotpassword'"
                            [class.disabled-link]="loggedIn" class="for_pass">Forgot Password?</a></p>
                    <div class="text-center login_btn">
                        <button type="submit" [disabled]="!loginForm.valid"
                            class="btn btn-center login-button btn-responsive">
                            <ng-container *ngIf="!(loggedIn && loginForm.valid)">Log In</ng-container>
                            <div *ngIf="(loggedIn && loginForm.valid)" class="loader xs-loader mx-auto"></div>
                        </button>
                    </div>
                </form>

                <!-- <p class="text-center"><a mat-button color="primary" (click)="loggedIn?null:redirectToOtpLogin()"
                        [class.disabled-link]="loggedIn" class="for_pass">Using one-time password?</a></p> -->
            </div>
        </div>
    </div>
</section>
<!-- <div *ngIf="loggedIn && loginForm.valid">
    <div class="loader page-loader"></div>
</div> -->