import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-maintainance',
  templateUrl: './maintainance.component.html',
  styleUrls: ['./maintainance.component.css']
})
export class MaintainanceComponent implements OnInit {

  platform= localStorage.getItem('platform');
  maintainanceIframeURL: string;
  constructor(
    public alertController: AlertController) { }

  ngOnInit(): void {
    const MAINTAINANCE_URL = environment.appUrl;
    this.maintainanceIframeURL = MAINTAINANCE_URL ;
    const iframe =  document.getElementById('maintainanceIframe') as HTMLIFrameElement;
    iframe.contentWindow.location.replace(this.maintainanceIframeURL);
  }

  showExitConfirm() {
    this.alertController
      .create({
        header: '',
        message: 'Do you want to close the app?',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Stay',
            role: 'cancel',
            handler: () => {
              console.log('Application exit prevented!');
            },
          },
          {
            text: 'Exit',
            handler: () => {
              navigator['app'].exitApp();
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

}
