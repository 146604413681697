<div style="height: 100%;">
  <section id="main_logo" class="d-none d-xl-block d-md-block brandBlueBackground">
    <div class="container">
      <nav class="navbar navbar-expand-lg p-0">
        <div class="navbar-brand d-inline">
          <a routerLink="/dashboard">
            <img src="/assets/images/logo/revenuedLogo.svg" class="img-fluid d-inline" alt="Revenued Logo" />
          </a>
        </div>
      </nav>
    </div>
  </section>

  <!-- <div style="background-color: #0040BA; height: 70px; width: 100%"></div>
  <div class="bluebackground1 bg-sub"></div>
  <div class="bluebackground"></div> -->

  <!-- <div class="brandBlueBackground bg-sub"></div> -->
  <!-- <div class="brandBlueBackground"></div> -->
  <div id="wrapper">
    <div id="content">
      <div class="container p-0">
        <section id="ac_summary" class="d-flex justify-content-center commonBrandBg">
          <div class="container d-md-block d-lg-block d-xl-block">
            <div *ngIf="!invalidOppId" class="card card1">
              <div class="summary-heading text-uppercase primaryPageHead">
                <span fxFlex></span>Understand Your Pricing<span fxFlex></span>
              </div>
              <div style="padding-left: 10px;">
                <p>Revenued offers financing that can be less expensive if you choose to repurchase your
                  receivables. The estimation calculator below can help you understand the different
                  possibilities.</p>
              </div>
              <!-- <div style="padding: 0 20px;">
                              <p>Go back to <a (click)="goToCashDraw()" href="javascript:void(0)">Cash Draw</a></p>
                          </div> -->
              <div>
                <div style="display: flex; padding: 20px; padding-left: 10px; justify-content: flex-start;"
                  class="inputs-container">
                  <!-- <div class="form-inline" style="padding-right: 20px;">
                                      <h6 class="m-0 formatText" style="text-transform:inherit !important; padding-right: 10px;">
                                          Term (Days)</h6>
                                      <span class="input-symbol-dollar">
                                          <input appNumbersOnly [(ngModel)]="term" name="term"
                                              (keyup)="calculateTable($event.target.value)"
                                              class="form-control amount-input" /></span>
                                  </div> -->
                  <div class="form-inline" class="form-inline input-gutter-cal">
                    <h6 class="m-0 formatText" style="text-transform:inherit !important;padding-right: 10px;">
                      Draw Amount ($)</h6>
                    <span class="input-symbol-dollar">
                      <input appNumbersOnly name="amount" [(ngModel)]="amount" (keyup)="calculateTable('')"
                        [disabled]="!factor" class="form-control amount-input" /></span>
                  </div>
                  <div class="form-inline">
                    <h6 class="m-0 formatText" style="text-transform:inherit !important;padding-right: 10px;">
                      Start Date</h6>
                    <nz-date-picker [(ngModel)]="selectedDate" class="form-control amount-input"
                      (ngModelChange)="calculateTable('')">
                    </nz-date-picker>
                  </div>
                  <!-- <div style="padding-left: 28px;">
                                      <button nz-button nzType="primary" class="request-funds" (click)="goToCashDraw()">
                                          Go Back To Cash Draw
                                      </button>
                                  </div> -->
                </div>
                <div>
                  <p style="padding: 8px; margin-top: 12px; font-size: 80%; font-style: italic;">
                    <b>Note:</b> The tool below is for illustrative purposes only and does not demonstrate a commitment
                    from either party. Revenued agrees to consider in good faith, any offer by Merchant to repurchase
                    any of the outstanding Purchased Amounts with respect to any one or more transactions, and will
                    disclose the price for any such repurchases that it is willing to accept (if any) on the <a
                      routerLink="/dashboard/paynow">Program Website</a>. However, Revenued in its sole
                    discretion may accept or reject any offer to Repurchase.
                  </p>
                </div>
                <!-- <br /> -->
                <div style="display: flex;" class="big-figures">
                  <div class="prepay-cal-daily-tag">
                    <h1 class="font-light text-white m-0" *ngIf="selectedOption == 'daily'">{{ dailyPayment | currency
                      }}</h1>
                    <h1 class="font-light text-white m-0" *ngIf="selectedOption == 'weekly'">{{ weeklyPayment | currency
                      }}</h1>
                    <h6 class="text-white m-0" *ngIf="selectedOption == 'daily'">Daily Payment</h6>
                    <h6 class="text-white m-0" *ngIf="selectedOption == 'weekly'">Weekly Payment</h6>
                  </div>
                  <div class="prepay-cal-total-pay-tag">
                    <h1 class="font-light text-white m-0">{{ startingBalance | currency }}</h1>
                    <h6 class="text-white m-0">Purchased Amount</h6>
                  </div>
                </div>
                <br />
                <div>
                  <nz-table class="prepay-cal" #basicTable [nzData]="payNowRows" [nzHideOnSinglePage]="true"
                    nzNoResult="No Data. Please select Draw Amount.">
                    <thead>
                      <tr>
                        <th nzWidth="135px"><span nz-tooltip nzTooltipTitle=""># of Periods</span></th>
                        <th nzWidth="160px"><span nz-tooltip nzTooltipTitle="">Biz Date to Pay
                            Now</span></th>
                        <th nzWidth="130px"><span nz-tooltip nzTooltipTitle="">Sum of
                            Payments</span></th>
                        <th nzWidth="130px"><span nz-tooltip nzTooltipTitle="">Remaining
                            Balance</span></th>
                        <th nzWidth="130px"><span nz-tooltip nzTooltipTitle="">PayNow
                            Balance</span></th>
                        <th nzWidth="130px"><span nz-tooltip nzTooltipTitle="">Total Paid</span>
                        </th>
                        <th nzWidth="130px"><span nz-tooltip nzTooltipTitle="">Total Cost of
                            Capital</span></th>
                        <th nzWidth="130px"><span nz-tooltip nzTooltipTitle="">Total
                            Savings</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of basicTable.data" class="prepay-cal-row">
                        <td>{{ selectedOption == 'daily' ? data.days: data.days/5 }}</td>
                        <td>{{data.date | date}}</td>
                        <td>{{data.sumOfDallies | currency }}</td>
                        <td>{{data.remainingBalance | currency }}</td>
                        <td>{{data.payNowBalance | currency}}</td>
                        <td>{{data.totalPaid | currency}}</td>
                        <td>{{data.totalCostCapital | currency}}</td>
                        <td>{{data.totalSavings | currency}}</td>
                      </tr>
                    </tbody>
                  </nz-table>
                  <nz-table class="mob-prepay-cal" #mobilePricingTable [nzData]="payNowRows" [nzHideOnSinglePage]="true"
                    nzNoResult="No Data. Please select Draw Amount.">
                    <thead>
                      <tr>
                        <th nzWidth=""><span nz-tooltip nzTooltipTitle=""># of Periods</span></th>
                        <th nzWidth=""><span nz-tooltip nzTooltipTitle="">Biz Date to Pay
                            Now</span></th>
                        <th nzWidth=""><span nz-tooltip nzTooltipTitle="">Sum of
                            Payments</span></th>
                        <th nzWidth=""><span nz-tooltip nzTooltipTitle="">Remaining
                            Balance</span></th>
                        <th nzWidth=""><span nz-tooltip nzTooltipTitle="">PayNow
                            Balance</span></th>
                        <th nzWidth=""><span nz-tooltip nzTooltipTitle="">Total Paid</span>
                        </th>
                        <th nzWidth=""><span nz-tooltip nzTooltipTitle="">Total Cost of
                            Capital</span></th>
                        <th nzWidth=""><span nz-tooltip nzTooltipTitle="">Total
                            Savings</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of mobilePricingTable.data" class="prepay-cal-row">
                        <td>{{ selectedOption == 'daily' ? data.days: data.days/5 }}</td>
                        <td>{{data.date | date}}</td>
                        <td>{{data.sumOfDallies | currency }}</td>
                        <td>{{data.remainingBalance | currency }}</td>
                        <td>{{data.payNowBalance | currency}}</td>
                        <td>{{data.totalPaid | currency}}</td>
                        <td>{{data.totalCostCapital | currency}}</td>
                        <td>{{data.totalSavings | currency}}</td>
                      </tr>
                    </tbody>
                  </nz-table>
                </div>
              </div>
              <!-- <p *ngIf="isLoggedIn()" style="padding: 8px; margin-top: 12px; font-size: 80%; font-style: italic;">
                                  <b>Note:</b> the tool above is for illustrative purposes only and does not demonstrate a
                                  commitment from either party. From time to time, Merchant may offer to repurchase (each,
                                  a “Repurchase”) up to the entire Purchased Amount with respect to any one or more Sales.
                                  Revenued agrees to consider any such offer in good faith, and to disclose the price for
                                  any such Repurchase offer that it is willing to accept (if any) on the <a
                                      routerLink="/dashboard/paynow">Program Website</a>. However, Revenued in its sole
                                  discretion may accept or reject any offer to Repurchase.
                              </p>
                              <p *ngIf="!isLoggedIn()" style="padding: 8px; margin-top: 12px; font-size: 80%; font-style: italic;">
                                  <b>Note:</b> the tool below is for illustrative purposes only and does not demonstrate a commitment from either party. Revenued agrees to consider in good faith, any offer by Merchant to repurchase any of the outstanding Purchased Amounts with respect to any one or more transactions, and will disclose the price for any such repurchases that it is willing to accept (if any) on the Program Website ([link to Request PayNow]). However, Revenued in its sole discretion may accept or reject any offer to Repurchase.
                              </p> -->
            </div>
            <div *ngIf="invalidOppId" class="card card1 errorCard">
              <p style="padding: 12px; padding-top: 90px; margin: 0 auto">
                Sorry, this is not a valid link. Please check the link or reach out to your sales
                representative for the right link for pricing.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <section id="footer">
    <app-footer noAuth="true"></app-footer>
  </section>
  <div class="md-hide splash-screen">
    <h4 style="color: #fff; font-weight: 300;">For a better experience</h4>
    <h2 style="color: #fff;">Please switch to portrait orientation.</h2>
  </div>
</div>