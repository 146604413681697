import { Component, ElementRef, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-errors-page",
  templateUrl: "./errors-page.component.html",
  styleUrls: ["./errors-page.component.scss"],
})
export class ErrorsPageComponent implements OnInit {
  code = undefined;
  constructor(private router: Router, private elementRef: ElementRef) {
    this.elementRef.nativeElement.ownerDocument
      .body.style.backgroundColor = '#fff';
  }

  ngOnInit(): void {
    
    if (this.router.url.split('/')[2]) {
      this.code = this.router.url.split('/')[2];
    }

  }
  clearData() {
    this.router.navigate(["/login"]).then((res) => {
      localStorage.clear();
    });
  }
}
