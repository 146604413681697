import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  platform = localStorage.getItem('platform');
  platformAppName = localStorage.getItem('platform_app_name');
  platformAppPackageName = localStorage.getItem('platform_app_package_name');
  platformAppVersionCode = localStorage.getItem('platform_app_version_code');
  platformAppVersionNumber = localStorage.getItem('platform_app_version_number');

  @Input() noAuth: string = "false";
  currentYear = new Date().getFullYear();
  constructor(private router: Router) { }
  ngOnInit() {

    // this.checkRouterFlag();
  }

  paymentAdjustmentActive() {
    this.router.navigate(['/dashboard/payment-adjustment']);
  }


  hideFlag(): boolean {
    return this.router.url === '/dashboard/account-settings';
  }


}
