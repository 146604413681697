import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AccountService } from 'src/app/core/account.service';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LoginFormErrorModalComponent } from '../../shared/login-form-error-modal/login-form-error-modal.component';
@Component({
  selector: 'app-createpassword',
  templateUrl: './createpassword.component.html',
  styleUrls: ['./createpassword.component.css', '../sharedStyles.scss']
})
export class CreatepasswordComponent implements OnInit {
  createPasswordForm: FormGroup;
  email: string;
  passwordToken: string;
  showPasswordNotError: boolean;
  typeofPasswordUpdate = "";
  isPwdFocussed: boolean;
  modalRef: BsModalRef;
  fieldTextType: boolean;
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  fieldTextType1: boolean;
  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType1;
  }
  constructor(public accountService: AccountService, public authService: AuthenticationService, private router: Router, private activatedRoute: ActivatedRoute, private fb: FormBuilder, private modalService: BsModalService) {

    this.createPasswordForm = this.fb.group({
      email: ['', [Validators.required]],
      newPassword: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&].{7,}')], asyncValidators: [] }],
      passwordToken: [],
      confirmPassword: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&].{7,}')], asyncValidators: [] }]
    })
  }
  isConfirmPwdFocussed = false;
  confirmpwdOnFocus() {
    this.isConfirmPwdFocussed = true;
  }
  confirmpwdOnFocusOut() {
    this.isConfirmPwdFocussed = false;
  }
  pwdOnFocus() {
    this.isPwdFocussed = true;
  }
  pwdOnFocusOut() {
    this.isPwdFocussed = false;
  }
  landingPageDescription;
  ngOnInit() {
    this.landingPageDescription = localStorage.getItem('LANDING_PAGE_DESCRIPTION') || 'Revenued offers a FlexLine + Card built for all businesses';
    const email = this.activatedRoute.snapshot.paramMap.get('email');
    const passwordToken = this.activatedRoute.snapshot.paramMap.get('passwordToken') || this.activatedRoute.snapshot.paramMap.get('token');
    // console.log(this.activatedRoute.snapshot);
    this.typeofPasswordUpdate = this.activatedRoute.routeConfig.path;
    // console.log("this.activatedRoute.pathFromRoot",this.activatedRoute.pathFromRoot);
    this.activatedRoute.url.subscribe((res) => {
      console.log("url", res)
    });
    this.activatedRoute.queryParams.subscribe(({ email, token }) => {
      // this.authService.checkPasswordToken({"email":email,"passwordToken":token})
      // .subscribe((res)=>{
      //   alert("HI")
      //   if(res['error']){

      //   }
      // },()=>{alert("Your Link Validity Expired. Please contact Amin");
      // this.router.navigate([`/`]);})
      this.createPasswordForm.patchValue({ email })
      this.createPasswordForm.patchValue({ passwordToken: token })
    })
    // this.authService.getConfigs();
  }
  get newPassword() {
    return this.createPasswordForm.get('newPassword')
  }
  get confirmPassword() {
    return this.createPasswordForm.get('confirmPassword')
  }
  loader = false;
  setPassword() {
    if (!this.createPasswordForm.touched) {
      // alert("Please fill the form to update the Password");
      const initialState = {
        title: '',
        message: "Required field(s) missing"
      };
      this.openModal(initialState);
      this.createPasswordForm.markAllAsTouched();
    }
    if (this.arePasswordsEqual() && this.createPasswordForm.valid) {
      console.log(this.createPasswordForm)
      var data = {
        email: this.createPasswordForm.value.email,
        passwordToken: this.createPasswordForm.value.passwordToken,
        newPassword: this.createPasswordForm.value.newPassword
      }
      this.loader = true;
      console.log(data)
      this.authService.updatePwd(data).subscribe(() => {
        this.loader = false;
        const initialState = {
          title: 'Success',
          message: "Password Created."
        };
        // this.createCase("Closed", "Website Support", "Password created successfully", `Password Creation`, () => {});
        this.openModal(initialState);
        this.router.navigate(['/login']);
        // window.location.reload();
      }, (err) => {
        this.loader = false;
        if (err) {
          console.log("Error", err)
        }
      });
    }
  }
  arePasswordsEqual() {
    console.log(this.confirmPassword.value, this.newPassword.value)
    if (this.confirmPassword.value !== this.newPassword.value) {
      // alert("New Password and Confirm Password are Not Equal");
      const initialState = {
        title: 'Error',
        message: "Password confirmation does not match"
      };
      this.openModal(initialState);
      return false;
    }
    return true;
  }
  openModal(initialState) {
    if (this.modalRef) {
      this.modalRef.hide();
    }

    this.modalRef = this.modalService.show(LoginFormErrorModalComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';
  }
  createCase(status: string, reason: string, description: string, subject: string, cb: Function) {
    this.accountService.createCase({ accId: localStorage.getItem("acctId"), subject, status, reason, description, submitter: window.localStorage.getItem('email') }).subscribe((res) => {
      console.log(res)
      if (cb) {
        cb(res);
      }
    }, (er) => console.error(er));
  }
  // setPassword(){
  //   console.log(this.createPasswordForm)
  // this.authService.updatePwd(this.createPasswordForm.value)
  // .subscribe(()=>{
  //   alert("Password Updation Successfull")
  //   this.router.navigate([`/`])
  // },(response)=>{
  //   alert(" Error Occurred. Please contact Administrator");
  //   //console.log(response)
  // })
  // }
}
