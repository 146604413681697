import { Injectable } from '@angular/core';

export const isGreaterThanToday = (someDate) => {
  // const today = new Date()
  // return someDate.getDate() == today.getDate() &&
  //   someDate.getMonth() == today.getMonth() &&
  //   someDate.getFullYear() == today.getFullYear()
  var specific_date = new Date(someDate); //yyyy-MM-dd
  var current_date = new Date();
  if (current_date.getDate() == specific_date.getDate() || current_date.getTime() > specific_date.getTime()) {
    return true;
  }
  else {
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})

export class SharedService {

  constructor() { }

}
