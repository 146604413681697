import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LoginFormErrorModalComponent } from '../../shared/login-form-error-modal/login-form-error-modal.component';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css', '../sharedStyles.scss']
})
export class ForgotpasswordComponent implements OnInit {
  loader = false;
  modalRef: BsModalRef;
  forgotPasswordForm: FormGroup;
  isFieldFocussed = false;
  constructor(public authService: AuthenticationService, private router: Router, private fb: FormBuilder, private http: HttpClient, private modalService: BsModalService) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  get email() {
    return this.forgotPasswordForm.get('email')
  }
  landingPageDescription;
  ngOnInit() {
    this.landingPageDescription = localStorage.getItem('LANDING_PAGE_DESCRIPTION') || 'Revenued offers a FlexLine + Card built for all businesses';
    // this.authService.getConfigs();
  }
  forgotPwdOnFocus() {
    this.isFieldFocussed = true;
  }
  forgotPwdOnFocusOut() {
    this.isFieldFocussed = false;
  }
  forgotPassword() {
    if (this.loader) {
      return;
    }
    if (this.forgotPasswordForm.valid) {
      this.loader = true;
      this.authService.forgotPwd(this.forgotPasswordForm.value)
        .subscribe(() => {
          this.loader = false;
          // alert("Reset Password link sent to your email. Please check!")
          const initialState = {
            title: '',
            message: "Please check your email for a link to reset your password"
          };
          this.openModal(initialState);
          this.router.navigate([`/login`])
        }, () => {
          this.loader = false;
          // alert("No account working with this email");
          const initialState = {
            title: 'Error',
            message: "Account does not exist"
          };
          this.openModal(initialState);
        })
    }
    else {
      // alert("Please enter your email to proceed");
      const initialState = {
        title: 'Error',
        message: "Please enter your email to proceed."
      };
      this.openModal(initialState);
    }
  }
  openModal(initialState) {
    if (this.modalRef) {
      this.modalRef.hide();
    }

    this.modalRef = this.modalService.show(LoginFormErrorModalComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';
  }
}
