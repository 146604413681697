import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { datadogRum } from '@datadog/browser-rum';

@Injectable({
  providedIn: 'root',
})
export class RumLogger {
  private initialized = false;
  constructor() {
    if (!environment?.datadog) {
      return;
    }
    datadogRum.init({
      applicationId: 'ac3d8256-4d3e-443d-8bb8-c2f38f418009',
      clientToken: 'pub8cb43d5da1993ea080626d47b04b43a5',
      site: 'datadoghq.com',
      service: (window.localStorage.getItem('DATADOG_HOST_NAME') || environment.appEnvName || 'local') + '_' + (window.localStorage.getItem('platform') || 'browser') + '_rum',//ionic_platform
      sampleRate: parseInt(window.localStorage.getItem('DATADOG_OPTION_SAMPLE_RATE')) || 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      trackSessionAcrossSubdomains: true,
      useSecureSessionCookie: true,
      useCrossSiteSessionCookie: true,
      silentMultipleInit: true,
      trackUserInteractions: true,
      trackFrustrations: true
    });
    this.initialized = true;
    if (window.localStorage.getItem('ENABLE_DATADOG_RUM_SESSION_REPLAY_RECORDING') === 'true') {
      //record rum metrics for ux monitoring using datadog
      datadogRum.startSessionReplayRecording();
    }
  }



  setCustomAction(name: any, data: any) {
    datadogRum.addAction(`${name}`, data);
  }
  setCustomUser(data: any) {
    datadogRum.setUser(data);
  }
  startCustomView(name: any) {
    datadogRum.startView(name);
  }
  getRumInitConfiguration() {
    return datadogRum.getInitConfiguration();
  }
}