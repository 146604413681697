<section id="maintainance_page" class="d-flex justify-content-center">
  <div class="container d-md-block d-lg-block d-xl-block p-2">
    <div class="card card1 right_col">
      <div class="container holds-the-iframe">
        <iframe id="maintainanceIframe" class="responsive-iframe" title="Maintainance Page"></iframe>

      </div>
    </div>
  </div>
</section>

<button *ngIf="platform==='Android'" type="button" class="btn btn-center mb-2" style="position: absolute; 
  bottom: 0px;
  width: 100%;"
 (click)="showExitConfirm()" >Exit</button>