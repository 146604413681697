import { HttpErrorResponse } from "@angular/common/http";
export class CaseHandler {
  getErrors(error) {
    let textMessage = "";
    let errorType = "";


    if (error instanceof HttpErrorResponse) {

      // A client-side or network error occurred. Handling it accordingly.
      if (error.status === 0) {
        // client-side error

        errorType = "Client Side Error";
        if (
          typeof error === "object" &&
          !Array.isArray(error) &&
          error !== null
        ) {
          textMessage = `Error:  ${error}`;
        } else {
          textMessage = `Error:  ${JSON.stringify(error)}`;
        }
      } else {
        // server-side error


        /*
        List of cases :
         -400 Bad Request
         -401 Unauthorized
         -404 Not Found
         -403 Forbidden
         -500 Internal Server Error
         -502 Bad Gateway
         -503 Service Unavailable
         -504 Gateway Timeout
         -Any other
        */
        errorType = "Server Side Error";
        switch (error.status) {
          case 401:
            textMessage = `Error Code: ${error.status} \nMessage: ${error?.message || "Undefined client error"
              }`;
            break;
          case 400:
            textMessage = `Error Code: ${error.status} \nMessage: ${error?.message || "Undefined client error"
              }`;
            break;
          case 404:
            textMessage = `Error Code: ${error.status} \nMessage: ${error?.message || "Undefined client error"
              }`;
            break;
          case 403:
            textMessage = `Error Code: ${error.status} \nMessage: ${error?.message || "Undefined client error"
              }`;
            break;
          case 500:
            textMessage = `Error Code: ${error.status} \nMessage: ${error?.message || "Undefined client error"
              }`;
            break;
          case 502:
            textMessage = `Error Code: ${error.status} \nMessage: ${error?.message || "Undefined client error"
              }`;
            break;
          case 503:
            textMessage = `Error Code: ${error.status} \nMessage: ${error?.message || "Undefined client error"
              }`;
            break;
          case 504:
            textMessage = `Error Code: ${error.status} \nMessage: ${error?.message || "Undefined client error"
              }`;
            break;
          default:
            if (error.statusText === "Unknown Error") {
              textMessage = `Error Code: ${error.statusText} \nMessage: ${error?.message || "Undefined client error"
                }`;
            } else {
              textMessage = `Error Code: ${error.status} \nMessage: ${error?.message || "Undefined client error"
                }`;
            }
            break;
        }
      }
    } else {
      // client-side error
      errorType = "Client Side Error";
      if (
        typeof error === "object" &&
        !Array.isArray(error) &&
        error !== null
      ) {
        textMessage = `Error:  ${error}`;
      } else {
        textMessage = `Error:  ${JSON.stringify(error)}`;
      }
    }

    let result = {
      status: error?.status ? error?.status : '',
      errorType: errorType,
      textMessage: textMessage
    }
    return result;

  }

}
