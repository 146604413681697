import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, IonSlides, MenuController } from '@ionic/angular';
import Swal from 'sweetalert2'
import * as $ from "jquery"

@Component({
  selector: 'app-walkthrough',
  templateUrl: './walkthrough.page.html',
  styleUrls: ['./walkthrough.page.scss'],
})
export class WalkthroughComponent implements OnInit {
  @ViewChild(IonSlides, { static: true }) slides: IonSlides;
  showSkip = true;
  slideOpts = {
    effect: 'flip',
    speed: 1000,
  };
  dir: String = 'ltr';
  slideList: Array<any> = [
    {
      title:
        '"Dashboard"',
      description:
        "",
      image: 'assets/slides/1.jpg',
    },
    {
      title:
        '"Transactions"',
      description:
        "",
      image: 'assets/slides/2.jpg',
    },
    {
      title: '"CashDraw"',
      description:
        '',
      image: 'assets/slides/3.jpg',
    },
    {
      title: '"PayNow"',
      description:
        '',
      image: 'assets/slides/4.jpg',
    },
    {
      title: '"Cards"',
      description:
        '',
      image: 'assets/slides/5.jpg',
    },
  ];
  constructor(
    public navCtrl: NavController,
    public menuCtrl: MenuController,
    public router: Router
  ) {
    this.menuCtrl.enable(false);
    console.log(this.slideList)
  }

  ngOnInit() {

    if (localStorage.getItem('skipIntro') === 'true') {
      this.goToNext();
    }
  }

  onSlideNext() {
    this.slides.slideNext(1000, false);
  }

  onSlidePrev() {
    this.slides.slidePrev(300);
  }

  // onLastSlide() {
  // 	this.slides.slideTo(3, 300)
  // }

  openHomeLocation() {


    Swal.fire({
      title: 'Skip Intro!',
      text: "from next time?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, SKIP!'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.setItem(
          'skipIntro',
          'true'
        );

        this.goToNext();
      } else {
        this.goToNext();
      }
    })
  }

  skipIntroCheck() {
    let skipCheck = $("#skipIntroCheck").prop('checked');
    if (skipCheck === true) {
      localStorage.setItem(
        'skipIntro',
        'true'
      );
      this.goToNext();
    } else {
      this.goToNext();
    }
  }

  goToNext() {
    this.router.navigate(['/dashboard']);
  }

  openLoginPage() {
    this.navCtrl.navigateForward('/login');
  }
}
