import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";

import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { CoreComponent } from "./core/core.component";
import { AuthService } from "./auth/auth.service";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PopoverModule } from "ngx-bootstrap/popover";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ModalModule } from "ngx-bootstrap/modal";
import { httpInterceptorProviders } from "./http-interceptors";
import { FormsModule } from "@angular/forms";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NZ_I18N } from "ng-zorro-antd/i18n";
import { en_US } from "ng-zorro-antd/i18n";
import { registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler } from "@angular/core";
import { GlobalErrorHandler } from "./errors/global-error-handler";
import { HttpLoadingInterceptor } from "./errors/http-loading.interceptor";
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { CommonModule, CurrencyPipe } from '@angular/common';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { InternetComponent } from "./shared/internet/internet.component";
import { IonicModule } from "@ionic/angular";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { Uid } from "@ionic-native/uid/ngx";
// import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions/ngx";
import { UniqueDeviceID } from "@ionic-native/unique-device-id/ngx";
import { Device } from "@awesome-cordova-plugins/device/ngx";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { WalkthroughComponent } from "./walkthrough/walkthrough.page";
import { AppVersion } from "@awesome-cordova-plugins/app-version/ngx";
import { AppRate } from '@awesome-cordova-plugins/app-rate/ngx';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from "../environments/environment";
const config: SocketIoConfig = { url: environment.baseUrl.replace(/\/$/, '') + ':8080', options: {} };

// const config: SocketIoConfig = { url: 'http://localhost:8080', options: {} };

registerLocaleData(en);
@NgModule({
  declarations: [AppComponent, InternetComponent, CoreComponent, WalkthroughComponent],
  imports: [
    IonicModule.forRoot(),
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    NzEmptyModule,
    NzTableModule,
    NzDropDownModule,
    NzButtonModule,
    NzModalModule,
    NzMenuModule,
    HttpClientModule,
    NzDatePickerModule,
    NzToolTipModule,
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    NgbModule,
    Ng2SearchPipeModule,
    ToastrModule.forRoot({
      timeOut: 3500,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    // NgxSkeletonLoaderModule,
    NgxSkeletonLoaderModule.forRoot({ animation: "pulse" }),
    SocketIoModule.forRoot(config)
  ],
  entryComponents: [
    InternetComponent,
    WalkthroughComponent
  ],
  providers: [
    WalkthroughComponent,
    InAppBrowser,
    AppVersion,
    AuthService,
    Uid,
    // AndroidPermissions,
    Device,
    UniqueDeviceID,
    Network,
    InternetComponent,
    StatusBar,
    CurrencyPipe,
    httpInterceptorProviders,
    AppRate,
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoadingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],

  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule { }
