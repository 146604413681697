import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { ErrorDialogService } from "../shared/errors/error-dialog.service";
import Swal from "sweetalert2";
import {
  environment,
  httpInterceptorEnvironment,
} from "src/environments/environment";
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private errorDialogService: ErrorDialogService,
    private zone: NgZone,
    private router: Router
  ) {}
  //global secondary error handler runs if environments varaibles wishes not to bypass
  handleError(error: any) {
    if (0) {
      //bypass the global error handler
      if (!httpInterceptorEnvironment.bypassGlobalErrorHandler) {
        // Check if it's an error from an HTTP response
        if (!(error instanceof HttpErrorResponse)) {
          error = error.rejection; // get the error object
        }
        this.zone.run(() => {
          let textMessage = "";
          let errorType = "";

          if (error instanceof HttpErrorResponse) {
            if (error.status === 0) {
              // A client-side or network error occurred. Handle it accordingly.
              // client-side error
              errorType = "Client Side Error";
              if (
                typeof error === "object" &&
                !Array.isArray(error) &&
                error !== null
              ) {
                textMessage = `Error:  ${error}`;
              } else {
                textMessage = `Error:  ${JSON.stringify(error)}`;
              }
            } else {
              // server-side error
              errorType = "Server Side Error";
              textMessage = `Error Code: ${error.status} \nMessage: ${
                error?.message || "Undefined client error"
              }`;
            }
          } else {
            // client-side error
            errorType = "Client Side Error";
            if (
              typeof error === "object" &&
              !Array.isArray(error) &&
              error !== null
            ) {
              textMessage = `Error:  ${error}`;
            } else {
              textMessage = `Error:  ${JSON.stringify(error)}`;
            }
          }

          Swal.fire({
            icon: "error",
            title: "ERROR HANDLER - " + errorType,
            text: textMessage,
            footer: '<a href="/">back to home...</a>',
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Close Session",
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.fire("Session closed!", "", "success");
            } else if (result.isDenied) {
              /// Swal.fire('Changes are not saved', '', 'info')
            }
          });
        });
      }
    }
  }
}
