<section id="upgrade_page" class="d-flex justify-content-center">
  <div class="container d-md-block d-lg-block d-xl-block p-2" style="background-color: #e9ecef;">

    <div class="jumbotron">
      <br><br>
      <div class="col md-12 col-lg-12 text-center">
        <img
          src="assets/images/update.png"
          width="200px" alt="Update" />
      </div>
      <br><br>
      <h1>Update Required!</h1>
      <p style="
      text-align: justify;">A new version of the application is available and is required to continue, please click
        below to update to the
        latest.</p>
      <p><button class="btn btn-primary btn-center  mb-2" style="
        width: 100%;" role="button"  (click)="goToDownload()">DOWNLOAD UPDATE</button></p>

    </div>
  </div>
</section>

<button type="button" *ngIf="platform==='Android'"  class="btn btn-center mb-2" style="position: absolute;
  bottom: 0px;
  width: 100%;" (click)="showExitConfirm()">Exit</button>