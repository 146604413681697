import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';

import { environment,httpInterceptorEnvironment } from './../../../environments/environment';
@Injectable()
export class LoadingDialogService {
  private opened = false;
  private dialogRef!: MatDialogRef<LoadingDialogComponent>;

  constructor(private dialog: MatDialog,

    private toastr: ToastrService
  ) { }

  openDialog(): void {
    //here we can show global spinner
    if (httpInterceptorEnvironment.showInterceptorLoader) {
      this.toastr.info('Interceptor! - spinner on', 'On Request!');
    }
  }

  hideDialog() {
    //here we can hide global spinner
    if (httpInterceptorEnvironment.showInterceptorLoader) {
      this.toastr.warning('Interceptor! - spinner off', 'On Handler!');
    }
  }
}
