import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MainareaComponent } from './mainarea/mainarea.component';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreatepasswordComponent } from './createpassword/createpassword.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { LoginFormErrorModalComponent } from './login-form-error-modal/login-form-error-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ConformationModalComponent } from './conformation-modal/conformation-modal.component';
import { GroupByPropertyPipe } from './pipes/group-by-property.pipe';
import { SharedService } from './services/shared.service';


import { ErrorDialogService } from './errors/error-dialog.service';
import { LoadingDialogService } from './loading/loading-dialog.service';
import { MaterialModule } from '../material.module';


import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { NgxCurrencyModule } from './rev-card-currency/currency-mask.module';
import { HeaderRightComponent } from './header-right/header-right.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { PricingCalComponent } from './pricing-cal/pricing-cal.component';
import { ErrorsPageComponent } from '../errors-page/errors-page.component';
import { OtpLoginComponent } from './otplogin/otplogin.component';
import { MFAOtpLoginComponent } from './mfa-otplogin/mfa-otplogin.component';
import { NoInternetComponent } from '../no-internet/no-internet.component';

@NgModule({
  declarations: [HeaderComponent, MainareaComponent, LoginComponent,
    FooterComponent,
    ErrorsPageComponent,
    NoInternetComponent,
    CreatepasswordComponent,
    ForgotpasswordComponent,
    OtpLoginComponent,
    MFAOtpLoginComponent,
    LoginFormErrorModalComponent,
    ConformationModalComponent,
    GroupByPropertyPipe,
    HeaderRightComponent,
    PricingCalComponent],
  imports: [
    CommonModule,
    ModalModule,
    NzEmptyModule,
    NzTableModule,
    NzDropDownModule,
    NzButtonModule,
    NzModalModule,
    NzMenuModule,
    NzSelectModule,
    NzPopoverModule,
    NzAnchorModule,
    NzSelectModule,
    NzDatePickerModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    CarouselModule.forRoot(),
    RouterModule.forChild([]),
    FormsModule,
    ReactiveFormsModule,
    NgxCurrencyModule,
    MaterialModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse' }),
  ],
  providers: [SharedService, NzMessageService, NzNotificationService, ErrorDialogService, LoadingDialogService],
  entryComponents: [
    LoginFormErrorModalComponent,
    ConformationModalComponent
  ],
  exports: [HeaderComponent, MainareaComponent, FooterComponent, HeaderRightComponent],

  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class SharedModule { }
